import bg from "../Assets/bg.jpg";
import avatar from "../Assets/avatar.svg";
import xcircle from "../Assets/x-circle.svg";
import { Form, Button, Container, Row, Col, Card } from "react-bootstrap";
import Header from "./Header";
import "./Main.css";
import data from "../Data/message.json";
import { useTranslation, Trans } from "react-i18next";
import { v4 as uuidv4 } from "uuid";
import axios from "axios";
import "react-phone-number-input/style.css";
import PhoneInput, {
  formatPhoneNumber,
  formatPhoneNumberIntl,
  isValidPhoneNumber,
} from "react-phone-number-input";
// "With country select" component.
import PhoneInputWithCountry from "react-phone-number-input/react-hook-form";

import { useForm } from "react-hook-form";
// ---------------------------------------------------

// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { useEffect, useState } from "react";
import {
  initializeAppCheck,
  ReCaptchaEnterpriseProvider,
} from "firebase/app-check";

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries
// import { getFirestore, collection, getDocs } from 'firebase/firestore/lite';
import {
  getFirestore,
  doc,
  setDoc,
  addDoc,
  serverTimestamp,
  collection,
  query,
} from "firebase/firestore";
import {
  getStorage,
  ref,
  uploadBytesResumable,
  getDownloadURL,
  connectStorageEmulator,
} from "firebase/storage";
import { Base64 } from "js-base64";
import Message from "./Message";

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyBHG1-5ovdFZxOlhkoV0i4VtrEDNiaab5A",
  authDomain: "digital-jyot.firebaseapp.com",
  projectId: "digital-jyot",
  storageBucket: "digital-jyot.appspot.com",
  messagingSenderId: "404756952674",
  appId: "1:404756952674:web:c74870dd51ad0e153b433b",
  measurementId: "G-E3RTP4R6YR",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
// Create a ReCaptchaEnterpriseProvider instance using your reCAPTCHA Enterprise
// site key and pass it to initializeAppCheck().
// const appCheck = initializeAppCheck(app, {
//   provider: new ReCaptchaEnterpriseProvider("6LeCQsseAAAAAALWiIz2mLffoOR2FyXapv_ohWRY"),
//   isTokenAutoRefreshEnabled: true // Set to true to allow auto-refresh.
// });

// const appCheck = appCheck();
// appCheck.activate(
//   new firebase.appCheck.ReCaptchaEnterpriseProvider(

//   ),
//   true // Set to true to allow auto-refresh.
// );

// const app = initializeApp({
//   // Your Firebase configuration object.
// });

// Create a ReCaptchaEnterpriseProvider instance using your reCAPTCHA Enterprise
// site key and pass it to initializeAppCheck().
// self.FIREBASE_APPCHECK_DEBUG_TOKEN = true;
// const appCheck = initializeAppCheck(app, {
//   provider: new ReCaptchaEnterpriseProvider(
//     "6LeCQsseAAAAAALWiIz2mLffoOR2FyXapv_ohWRY"
//   ),
//   isTokenAutoRefreshEnabled: true, // Set to true to allow auto-refresh.
// });

const analytics = getAnalytics(app);
const db = getFirestore(app);
const storage = getStorage();
const _uuid = uuidv4();

// --------------------------------------------------------

function MessageForm() {
  const { t, i18n } = useTranslation();

  const [showMessage, setShowMessage] = useState(false);
  const [userData, setuserData] = useState({
    name: "",
    mobile: "",
    email: "",
    message_id: "",
    timestamp: serverTimestamp(),
    code: 1,
    image_name: "",
  });

  const [tributeId, setTributeId] = useState();
  const [image, setImage] = useState(null);
  const [userImage, setUserImage] = useState();
  const [imageUploadProgress, setimageUploadProgress] = useState();
  const [avatarImg, setAvatarImg] = useState(true);
  const [activeCard, setActiveCard] = useState(null);
  const [disableSubmit, setDisableSubmit] = useState(true);
  const [generatedImageName, setGeneratedImageName] = useState("");
  const [tributeCounts, setTributeCounts] = useState();
  const [phone, setPhone] = useState();
  const [showLoad, setShowLoad] = useState(false);
  const [apiCalled, setApiCalled] = useState();

  /**
   *
   * @param {Object} data - Data to be stored in the database
   */
  const saveTribute = (filename) => {
    console.log("Save Tribute Called");
    userData.image_name = filename || "";

    setimageUploadProgress(" Saving Tribute....");
    axios
      .post(
        "https://asia-south1-digital-jyot.cloudfunctions.net/addTribute",
        userData
      )
      .then(function (response) {
        console.log(response);
        setTributeCounts(response.data);
        setShowMessage(true);
        setimageUploadProgress(" Saved!");
      })
      .catch(function (error) {
        console.log(error);
        setShowLoad(false);
        alert("Something went wrong. Please try again!");
      });

    // addDoc(collection(db, "tributes"), userData)
    //   .then((docRef) => {
    //     console.log("Document written with ID: ", docRef);
    //     setTributeId(docRef.id);
    //     setShowMessage(true);
    //   })
    //   .catch((error) => {
    //     console.error("Error adding document: ", error);
    //   });
  };

  /**
   *
   * @param {*} e - Event object
   * @description - Set File object to the state
   */
  const onFileChange = (e) => {
    setAvatarImg(false);
    if (
      e.target.files[0].type.startsWith("image/") &&
      e.target.files[0].size <= 15000000
    ) {
      setImage(e.target.files[0]);
      setUserImage(URL.createObjectURL(e.target.files[0]));
    } else {
      console.log(e.target.files[0]);
      console.log("File size/type not supported");
      alert("File size limit exceeded, maximum 5 MB.");
    }
  };

  /**
   *
   * @description - Uploads the image to the storage
   */
  const uploadUserImage = (_) => {
    console.log("Upload Image Called");

    setGeneratedImageName(_uuid + "-" + image.name.replace("/", ""));
    let imageUuidName = _uuid + "-" + image.name.replace("/", "");
    const storageRef = ref(storage, "user_images/" + imageUuidName);

    const uploadTask = uploadBytesResumable(storageRef, image);
    uploadTask.on(
      "state_changed",
      (snapshot) => {
        const progress =
          (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        setimageUploadProgress("Uploading Image: " + progress.toFixed() + "%");
        console.log(progress);
        switch (snapshot.state) {
          case "paused":
            console.log("Upload is paused");
            break;
          case "running":
            console.log("Upload is running");
            break;
        }
      },
      (error) => {
        // Handle unsuccessful uploads
      },
      () => {
        saveTribute(imageUuidName);
        // Handle successful uploads on complete
        // For instance, get the download URL: https://firebasestorage.googleapis.com/...
        // getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
        //   // console.log("File available at", downloadURL);
        //   setShowMessage(true);
        // });
      }
    );
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setuserData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleSubmit = (_) => {
    setShowLoad(true);
    !avatarImg ? uploadUserImage() : saveTribute();
  };

  return (
    <>
      <div
        className="img-fluid"
        style={{
          backgroundPosition: "center",
          backgroundImage: `url(${bg})`,
          backgroundSize: "cover",
          textAlign: "center",
          backgroundRepeat: "no-repeat",
          overflow: "hidden",
          // height: "100vh",
          color: "#C1A56E",
        }}
      >
        {!showMessage ? (
          <>
            <Header />

            <Container className="">
              <Row>
                <Col>
                  <Form className="message-form form-font notextselection">
                    <div className="pb-5">
                      <input
                        className="avatar  notextselection img-fluid "
                        style={{
                          backgroundImage: avatarImg
                            ? `url(${avatar})`
                            : `url(${xcircle})`,
                          textAlign: "center",
                          backgroundPosition: "center",
                          backgroundRepeat: "no-repeat",
                          height: "90px",
                          width: "80px",
                        }}
                        type="file"
                        onChange={onFileChange}
                        accept="image/jpg,image/png,image/x-png,image/jpeg"
                      />

                      {userImage && (
                        <>
                          <img id="userImage" src={userImage} alt="Avatar" />
                        </>
                      )}
                      <p>{t("upload_image")}</p>
                    </div>

                    <Form.Group
                      className="mb-3 form-inline"
                      controlId="formBasicEmail"
                    >
                      <Row>
                        <Col lg={3} style={{ textAlign: "start" }}>
                          <Form.Label>{t("name")}*</Form.Label>
                        </Col>
                        <Col lg={9}>
                          <Form.Control
                            type="text"
                            maxLength="20"
                            minLength="1"
                            name="name"
                            required
                            autoComplete="off"
                            value={userData.name}
                            onChange={handleChange}
                          />
                        </Col>
                      </Row>
                    </Form.Group>

                    <Form.Group className="mb-3" controlId="formBasicPassword">
                      <Row>
                        <Col lg={3} style={{ textAlign: "start" }}>
                          <Form.Label>{t("email")}*</Form.Label>
                        </Col>
                        <Col lg={9}>
                          <Form.Control
                            type="email"
                            name="email"
                            maxLength="50"
                            autoComplete="off"
                            required={true}
                            value={userData.email}
                            onChange={handleChange}
                          />
                        </Col>
                      </Row>
                    </Form.Group>

                    <Form.Group className="mb-3" controlId="formBasicPassword">
                      <Row>
                        <Col lg={3} style={{ textAlign: "start" }}>
                          <Form.Label>{t("phone")}</Form.Label>
                        </Col>
                        <Col lg={9}>
                          <PhoneInput
                            placeholder="8888888888"
                            defaultCountry="IN"
                            value={phone}
                            limitMaxLength={true}
                            error={
                              phone
                                ? isValidPhoneNumber(phone)
                                  ? undefined
                                  : "Invalid phone number"
                                : "Phone number required"
                            }
                            onChange={(e) => {
                              setuserData((prevState) => ({
                                ...prevState,
                                mobile: e,
                              }));
                            }}
                          />
                        </Col>
                      </Row>
                    </Form.Group>

                    <Form.Group className="mb-3" controlId="formBasicPassword">
                      <Row>
                        <Col lg={3} style={{ textAlign: "start" }}>
                          <Form.Label>{t("message")}*</Form.Label>
                        </Col>
                        <Col lg={9}>
                          <div>
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "space-between",
                              }}
                            />

                            <span
                              style={{ overflowX: "auto", display: "flex" }}
                            >
                              {t("quote", {
                                returnObjects: true,
                              }).map((item, index) => (
                                <Card
                                  id="messageId"
                                  key={index}
                                  className={
                                    activeCard === item.id
                                      ? "message-active "
                                      : "message-card"
                                  }
                                  style={{ minWidth: "12rem", margin: "5px" }}
                                  value={item.id}
                                  onClick={() => {
                                    setActiveCard(item.id);
                                    setuserData((prevState) => ({
                                      ...prevState,
                                      message_id: item.id,
                                    }));
                                  }}
                                >
                                  <Card.Body>
                                    {/* <Card.Title>{item.id}</Card.Title> */}
                                    <Card.Text>{item.message}</Card.Text>
                                  </Card.Body>
                                </Card>
                              ))}
                            </span>
                          </div>
                        </Col>
                      </Row>
                    </Form.Group>
                    <Row className="py-3 ">
                      <Col>
                        {showLoad ? (
                          <>
                            {/* <div class="spinner-border" role="status">
                              <span class="visually-hidden">Loading...</span>
                            </div> */}
                            {imageUploadProgress}
                          </>
                        ) : (
                          <Button
                            className="submitBtn"
                            type="button"
                            onClick={() => {
                              // console.log(userData);
                              handleSubmit();
                            }}
                            disabled={
                              !userData.message_id ||
                              !userData.name ||
                              !userData.email
                                ? true
                                : false
                            }
                          >
                            {t("submit")}
                          </Button>
                        )}
                      </Col>
                    </Row>
                  </Form>
                </Col>
              </Row>
            </Container>
          </>
        ) : (
          <Message
            avatar={userImage}
            data={userData}
            tributeCounts={tributeCounts}
          />
        )}
      </div>
    </>
  );
}

export default MessageForm;
