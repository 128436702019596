import { Container, Row, Col, NavDropdown } from "react-bootstrap";
import ministryLogo from "../Assets/ministrylogo.png";
import azadiLogo from "../Assets/azadi.svg";
import lang from "../Assets/languagee.svg";
// import live from "../Assets/live.svg";
import { useTranslation, Trans } from "react-i18next";
import { useNavigate } from "react-router-dom";

function Header() {
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();
  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
  };

  const liveAction = () => {
    navigate("/live");
  };
  return (
    <>
      <Container fluid="xxxl">
        <Row>
          <Col xs lg="6">
            <a className="ministryLogo px-2" href="/">
              <img src={ministryLogo} className="img-fluid"></img>
            </a>
          </Col>

          <Col xs lg="6">
            <a className="subLogo px-2">
              <img src={azadiLogo} className="img-fluid"></img>
            </a>
          </Col>

          <Col xs lg="1" className="lang_div">
            <NavDropdown
              title={
                <div className="langLogo float">
                  <img
                    className="my-float img-fluid lang"
                    src={lang}
                    alt="Language"
                  />
                </div>
              }
              id="basic-nav-dropdown"
            >
              <NavDropdown.Item onClick={() => changeLanguage("en")}>
                English
              </NavDropdown.Item>
              <div class="dropdown-divider"></div>
              <NavDropdown.Item onClick={() => changeLanguage("hin")}>
                हिन्दी
              </NavDropdown.Item>
              <div class="dropdown-divider"></div>
              <NavDropdown.Item onClick={() => changeLanguage("guj")}>
                ગુજરાતી
              </NavDropdown.Item>
              <div class="dropdown-divider"></div>
              <NavDropdown.Item onClick={() => changeLanguage("tam")}>
                தமிழ்
              </NavDropdown.Item>
              <div class="dropdown-divider"></div>
              <NavDropdown.Item onClick={() => changeLanguage("tel")}>
                తెలుగు
              </NavDropdown.Item>
              <div class="dropdown-divider"></div>
              <NavDropdown.Item onClick={() => changeLanguage("mal")}>
                മലയാളം
              </NavDropdown.Item>
              <div class="dropdown-divider"></div>
              <NavDropdown.Item onClick={() => changeLanguage("mar")}>
                मराठी
              </NavDropdown.Item>
              <div class="dropdown-divider"></div>
              <NavDropdown.Item onClick={() => changeLanguage("kan")}>
                ಕನ್ನಡ
              </NavDropdown.Item>
              <div class="dropdown-divider"></div>
              <NavDropdown.Item onClick={() => changeLanguage("ban")}>
                বাংলা
              </NavDropdown.Item>
              {/* <div class="dropdown-divider"></div>
              <NavDropdown.Item onClick={() => changeLanguage("as")}>
                অসমীয়া
              </NavDropdown.Item>



              <div class="dropdown-divider"></div>
              <NavDropdown.Item onClick={() => changeLanguage("od")}>
                ഒറിയ
              </NavDropdown.Item>
              <div class="dropdown-divider"></div>
              <NavDropdown.Item onClick={() => changeLanguage("pun")}>
                ਪੰਜਾਬੀ
              </NavDropdown.Item>

*/}
            </NavDropdown>
          </Col>
        </Row>
        {/* <Row>
          <Col style={{ textAlign: " -webkit-right" }}>
            <div
              className="img-fluid live d-flex justify-content-center"
              onClick={() => {
                navigate("/");
              }}
              style={{
                backgroundImage: `url(./active.svg)`,
                backgroundRepeat: "no-repeat",
                position: "relative",
                height: "35px",
                maxWidth: "70px",
                padding: "7px",
              }}
            >
              <p className="btn-text black">{t("home")}</p>
            </div>
          </Col>
          <Col style={{ textAlign: "start" }}>
            <div
              className="img-fluid live d-flex justify-content-center"
              onClick={() => {
                navigate("/about");
              }}
              style={{
                backgroundImage: `url(./inactive.svg)`,
                backgroundRepeat: "no-repeat",
                position: "relative",
                height: "35px",
                maxWidth: "70px",
                padding: "7px",
              }}
            >
              <p className="btn-text cream">{t("about")}</p>
            </div>
          </Col>
        </Row> */}
      </Container>
    </>
  );
}

export default Header;
