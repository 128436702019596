import loop_rings from "../Assets/temp.jpg";
import beam from "../Assets/beam1.png";
import "./Main.css";
import { Container, Row, Col, NavDropdown } from "react-bootstrap";
import { useTranslation, Trans } from "react-i18next";
import Header from "./Header";
import Form from "./Form";
import { useEffect, useState } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";

function Main() {
  const { t, i18n } = useTranslation();
  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
  };

  const [show, setShow] = useState(false);
  const [tributeCount, setTributeCount] = useState(0);

  useEffect(() => {
    axios
      .get(
        ` https://asia-south1-digital-jyot.cloudfunctions.net/getTributeCount`
      )
      .then((res) => {
        console.log(res.data.count);
        setTributeCount(res.data.count);
      });
  }, []);

  const navigate = useNavigate();
  return (
    <>
      <div
        className="img-fluid main-cont"
        style={{
          backgroundPosition: "center",
          backgroundImage: `url(${loop_rings})`,
          backgroundSize: "cover",
          textAlign: "center",
          backgroundRepeat: "no-repeat",
          backgroundColor: "#040302",
          fontFamily: "sans-serif",
          // overflowY: "scroll",
          overflowX: "hidden",
          color: "#C1A56E",
        }}
      >
        {!show ? (
          <>
            <Header />
            <Row>
              <Col style={{ textAlign: " -webkit-right" }}>
                <div
                  className="img-fluid live d-flex justify-content-center"
                  onClick={() => {
                    navigate("/");
                  }}
                  style={{
                    backgroundImage: `url(./active.svg)`,
                    backgroundRepeat: "no-repeat",
                    position: "relative",
                    height: "35px",
                    maxWidth: "70px",
                    padding: "7px",
                  }}
                >
                  <p className="btn-text black">{t("home")}</p>
                </div>
              </Col>
              <Col style={{ textAlign: "start" }}>
                <div
                  className="img-fluid live d-flex justify-content-center"
                  onClick={() => {
                    navigate("/about");
                  }}
                  style={{
                    backgroundImage: `url(./inactive.svg)`,
                    backgroundRepeat: "no-repeat",
                    position: "relative",
                    height: "35px",
                    maxWidth: "70px",
                    padding: "7px",
                  }}
                >
                  <p className="btn-text cream">{t("about")}</p>
                </div>
              </Col>
            </Row>
            <div className="container-xxl" style={{ height: "100vh" }}>
              <Row className="main_content d-flex justify-content-center">
                <Col lg={3} className="tribute_cont">
                  <p className="digital_intro font-eng text-center">
                    {t("tribute_garden")}
                  </p>
                  <p className="dig-sub digital_text">{t("digital_intro")}</p>
                  <div className="instructions">
                    <p className="digital_text_step">{t("instructions")}</p>
                    <span className="digital_text_step">{t("step1")}</span>
                    <span className="digital_text">{t("step1_inst")}</span>
                    <br />
                    <span className="digital_text_step">{t("step2")}</span>
                    <span className="digital_text">{t("step2_inst")}</span>
                    <br />
                    <span className="digital_text_step">{t("step3")}</span>
                    <span className="digital_text">{t("step3_inst")}</span>
                    <br />
                    <span className="digital_text_step">{t("step4")}</span>
                    <span className="digital_text">{t("step4_inst")}</span>
                    <br />
                    <div
                      className="mt-5 img-fluid live d-flex justify-content-center submitBtn"
                      // style={{ maxWidth: "150px", minWidth: "150px" }}
                      onClick={() => {
                        setShow(true);
                      }}
                    >
                      <Row
                        className="align-items-center"
                        style={{ height: "60px" }}
                      >
                        <Col xs lg={4} style={{ textAlign: " -webkit-right" }}>
                          <img src="./tribute1.svg"></img>
                        </Col>
                        <Col
                          xs
                          lg={8}
                          className="d-flex justify-content-center"
                        >
                          <p
                            className={
                              t("lang") === "Tamil" || t("lang") === "Malyalam"
                                ? " digital_text_step"
                                : "digital_text_step text-nowrap"
                            }
                            style={{ margin: "2px" }}
                          >
                            {t("pay_tribute")}
                          </p>
                        </Col>
                      </Row>
                    </div>
                    {/* <div
                      className="mt-5 img-fluid live d-flex justify-content-center"
                      style={{
                        backgroundImage: `url(./pay_tribute.svg)`,
                        backgroundRepeat: "no-repeat",
                        position: "relative",
                        height: "70px",
                        maxWidth: "200px",
                        padding: "24px",
                      }}
                      onClick={() => {
                        setShow(true);
                      }}
                    >
                      <p className="btn-text cream">{t("pay_tribute")}</p>

                    </div> */}
                  </div>
                </Col>
                <Col lg={6} className="py-2">
                  <img className="img-fluid beam" src={beam}></img>
                </Col>
                <Col lg={3} className=" count-section align-items-center mt-5">
                  <div className="instructions2">
                    <p className="digital_text_step">{t("instructions")}</p>
                    <span className="digital_text_step">{t("step1")}</span>
                    <span className="digital_text">{t("step1_inst")}</span>
                    <br />
                    <span className="digital_text_step">{t("step2")}</span>
                    <span className="digital_text">{t("step2_inst")}</span>
                    <br />
                    <span className="digital_text_step">{t("step3")}</span>
                    <span className="digital_text">{t("step3_inst")}</span>
                    <br />
                    <span className="digital_text_step">{t("step4")}</span>
                    <span className="digital_text">{t("step4_inst")}</span>
                    <br />
                    <br />
                    <div
                      className="mt-5 img-fluid live  submitBtn"
                      // style={{ maxWidth: "150px", minWidth: "150px" }}
                      onClick={() => {
                        setShow(true);
                      }}
                    >
                      <Row
                        className="align-items-center d-flex justify-content-center"
                        style={{ height: "80px" }}
                      >
                        <Col xs lg={4} style={{ textAlign: "text-center" }}>
                          <img src="./tribute1.svg"></img>
                          {/* </Col>
                        <Col
                          xs
                          lg={8}
                          style={{ textAlign: "start" }}
                          // className="d-flex justify-content-center"
                        > */}
                          <p
                            className="pt-2 digital_text_step text-nowrap"
                            style={{ margin: "2px" }}
                          >
                            <span className="text-nowrap">
                              {t("pay_tribute")}
                            </span>
                          </p>
                        </Col>
                      </Row>
                    </div>
                    {/* <a
                      className="tribute my-2"
                      onClick={() => {
                        setShow(true);
                      }}
                    > */}
                    {/* <Row
                      className="align-items-center"
                      style={{ height: "60px" }}
                      onClick={() => {
                        setShow(true);
                      }}
                    >
                      <Col xs lg={4} style={{ textAlign: " -webkit-right" }}>
                        <img src="./tribute1.svg"></img>
                      </Col>
                      <Col xs lg={8} className="d-flex justify-content-center">
                        <p
                          className="pt-2 digital_text_step"
                          style={{ margin: "2px" }}
                        >
                          {t("pay_tribute")}
                        </p>
                      </Col>
                    </Row> */}
                    {/* </a> */}
                  </div>
                  <br />
                  <div className="countBox">
                    <span className="digital_text_step">{t("note_head")}</span>
                    <span className="digital_text py-5  note">{t("note")}</span>
                    <br />
                    <br />
                    <br />
                    <p className="count text-center"> {tributeCount}</p>
                  </div>
                  <p
                    className="digital_text countBox "
                    style={{ paddingTop: "10px" }}
                  >
                    {t("tribute_paid_so_far")}
                  </p>
                  <div className="lang_main ">
                    <NavDropdown
                      title={
                        <div
                          className="mt-5 img-fluid live d-flex justify-content-center submitBtn"
                          style={{ height: "60px" }}
                        >
                          <Row className="align-items-center">
                            <Col>
                              <img src="./languagee.svg"></img>
                            </Col>
                            <Col
                              className="d-flex justify-content-center"
                              style={{ padding: "10px" }}
                            >
                              <p
                                className="digital_text_step"
                                style={{ margin: "2px" }}
                              >
                                {t("change_language")}
                              </p>
                            </Col>
                          </Row>
                        </div>
                      }
                      id="basic-nav-dropdown"
                    >
                      <div className="dropdown">
                        <NavDropdown.Item onClick={() => changeLanguage("en")}>
                          English
                        </NavDropdown.Item>
                        <div class="dropdown-divider"></div>
                        <NavDropdown.Item onClick={() => changeLanguage("hin")}>
                          हिन्दी
                        </NavDropdown.Item>

                        {/* <div class="dropdown-divider"></div>
                        <NavDropdown.Item onClick={() => changeLanguage("as")}>
                          অসমীয়া
                        </NavDropdown.Item>

                        <div class="dropdown-divider"></div>
                        <NavDropdown.Item onClick={() => changeLanguage("kan")}>
                          ಕನ್ನಡ
                        </NavDropdown.Item>
                        

                        <div class="dropdown-divider"></div>
                        <NavDropdown.Item onClick={() => changeLanguage("od")}>
                          ଓଡିଆ
                        </NavDropdown.Item>
                        <div class="dropdown-divider"></div>
                        <NavDropdown.Item onClick={() => changeLanguage("pun")}>
                          ਪੰਜਾਬੀ
                        </NavDropdown.Item>
                      

                         */}
                        <div class="dropdown-divider"></div>
                        <NavDropdown.Item onClick={() => changeLanguage("tel")}>
                          తెలుగు
                        </NavDropdown.Item>
                        <div class="dropdown-divider"></div>
                        <NavDropdown.Item onClick={() => changeLanguage("mal")}>
                          മലയാളം
                        </NavDropdown.Item>
                        <div class="dropdown-divider"></div>
                        <NavDropdown.Item onClick={() => changeLanguage("guj")}>
                          ગુજરાતી
                        </NavDropdown.Item>
                        <div class="dropdown-divider"></div>
                        <NavDropdown.Item onClick={() => changeLanguage("mar")}>
                          मराठी
                        </NavDropdown.Item>
                        <div class="dropdown-divider"></div>
                        <NavDropdown.Item onClick={() => changeLanguage("tam")}>
                          தமிழ்
                        </NavDropdown.Item>
                        <div class="dropdown-divider"></div>
                        <NavDropdown.Item onClick={() => changeLanguage("ban")}>
                          বাংলা
                        </NavDropdown.Item>
                      </div>
                    </NavDropdown>
                  </div>
                </Col>
              </Row>
            </div>
          </>
        ) : (
          <Form />
        )}
      </div>
    </>
  );
}

export default Main;
