import { Container, Row, Col } from "react-bootstrap";
import { useTranslation, Trans } from "react-i18next";
import ministryLogo from "../Assets/ministrylogo.png";
import azadiLogo from "../Assets/azadi.svg";
import "./Main.css";

function Message(props) {
  const { t, i18n } = useTranslation();
  return (
    <>
      <Container fluid>
        <Row>
          <Col xs lg="6">
            <a className="ministryLogo px-2" href="/">
              <img src={ministryLogo} className="img-fluid"></img>
            </a>
          </Col>

          <Col xs lg="6">
            <a className="subLogo">
              <img src={azadiLogo} className="img-fluid"></img>
            </a>
          </Col>
        </Row>
      </Container>
      <Container className="message-cont ">
        <Row>
          <Col>
            <div className="message-form form-font">
              <br />

              {/* <p className="messageTitle"> {props.data.name}</p> */}

              {/* {t("quote", {
                returnObjects: true,
              }).map((item, index) =>
                item.id === props.data.message_id ? (
                  <p key={index} className="messageText">
                    {item.message}
                  </p>
                ) : (
                  <p key={index}></p>
                )
              )} */}

              {/* {props.avatar && (
                <div>
                  <img
                    className="photo-rect my-2 img-fluid"
                    src={props.avatar}
                  ></img>
                </div>
              )} */}

              <p className="messageFooter">
                {t("thankyou")} <span className="name">{props.data.name}</span>{" "}
                {t("thankyou_message")}
              </p>
              <p className="messageText">
                {t("tribute_number")} {props.tributeCounts.your_tribute_number}
              </p>
              <p className="messageText">
                {t("current_tribute_number")}{" "}
                {props.tributeCounts.current_tribute_number}
              </p>
              <div style={{ textAlign: "-webkit-center" }}>
                <a
                  className="img-fluid live d-flex justify-content-center"
                  href="/"
                  style={{
                    backgroundImage: `url(./active.svg)`,
                    backgroundRepeat: "no-repeat",
                    // position: "relative",
                    height: "35px",
                    maxWidth: "70px",
                    padding: "7px",
                  }}
                >
                  <p className="btn-text black">{t("home")}</p>
                </a>
              </div>

              {/* <div>
                <a className="img-fluid" href="/">
                  <img src={t("home_btn")}></img>
                </a>
              </div> */}
            </div>
          </Col>
        </Row>
      </Container>
    </>
  );
}

export default Message;
