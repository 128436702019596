import i18n from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import { initReactI18next } from "react-i18next";

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    // we init with resources
    resources: {
      en: {
        translations: {
          lang: "English",
          home: "Home",
          click_to_pay_tribute: "Click To Pay Tribute",
          click_to_change_lang: "Click To Change Language",
          pay_tribute: "Pay Tribute",
          change_language: "Change Language",
          tribute_btn: "./tribute.svg",
          lang_btn: "./language.svg",
          digital_jyot: "Digital Jyot",
          tribute_garden: "Digital Jyot : Tribute to Martyrs ",
          digital_intro:
            "Light up a Digital Jyot at Central Park in Connaught Place, New Delhi by paying your tribute.",
          instructions: "Follow steps below",
          step1: "Step 1 : ",
          step2: "Step 2 : ",
          step3: "Step 3 : ",
          step4: "Step 4 : ",
          step1_inst: "Click your picture ",
          step2_inst: "Enter your details ",
          step3_inst: "Select your message",
          step4_inst: "Pay your tribute ",
          note_head: "NOTE : ",
          note: "Your tribute will flash in LED Screen in Central Park (Connaught Place) and will light the Digital flame in the sky. A video recording of your tribute will be shared via email/WhatsApp.",
          about_desc1:
            "Our freedom is made possible by the sacrifices of those who came before us. They gave up their present for our future. Today and every day is an opportunity for us to say thank you on behalf of a grateful nation. We pay tribute to their courage and sacrifice. ",
          about_desc2:
            "The DIGITAL JYOT installed at the heart of the capital city of India at Central Park in Connaught Place, Delhi symbolizes the glow of freedom that illuminates our lives. The digital jyot is surrounded by an illuminated metallic floral sculpture inspired by the courage and determination of our freedom fighters and martyrs. The design of the floral sculpture finds its inspiration from nature and flowers linked to ideas of hope, strength, and positivity. ",
          about_desc3:
            "As we celebrate Azadi Ka Amrit Mahotsav commemorating 75 years of independence the digital jyot reminds us that  'a person may no longer be there but his or her ideas live on forever.'. ",
          about_desc4:
            "So how can you pay tribute to those who made possible your freedom?",
          about_desc5:
            "Your tribute will be queued up for delivery and will flash on the LED screen installed at Central Park, Connaught Place, Delhi illuminating the digital jyot in the sky.",
          about_desc6:
            "A video recording of your tribute will be shared with you once your tribute is showcased on the screen. ",
          about_desc7:
            "Please note - LED Screen will be operational post-sunset to ensure maximum visibility but tribute messages can be sent 24*7 ",

          live: "Live",
          about: "About",
          tribute_paid_so_far: "Tributes paid so far.",
          submit: "Submit",
          name: "Your Name",
          email: "Email Id",
          phone: "Phone Number",
          message: "Select Message",
          upload_image: "Upload Image",
          tribute_number: "Your Tribute Number : ",
          current_tribute_number: "Current Tribute Number : ",
          quote: [
            {
              id: 1,
              message:
                "They sacrificed their today for our tomorrow. A grateful nation says THANK YOU!",
            },
            {
              id: 2,
              message:
                "We remember all those who made our freedom possible. We will NEVER FORGET!",
            },
            {
              id: 3,
              message:
                "A nation remembers all those who fought, sacrificed and ensured our freedom. We are GRATEFUL.",
            },
            {
              id: 4,
              message:
                "A person may no longer be there but their ideas live on. LONG LIVE INDEPENDENCE!",
            },
            {
              id: 5,
              message:
                "We salute our freedom fighters who taught us NATION FIRST & ALWAYS FIRST.",
            },
          ],

          thankyou: "Thank you",
          thankyou_message:
            " for being part of this campaign.You will get the video recording of your tribute via email",
        },
      },
      hin: {
        translations: {
          lang: "hindi",
          home: "होम ",
          click_to_pay_tribute: "Click To Pay Tribute",
          click_to_change_lang: "Click To Change Language",
          pay_tribute: "श्रद्धांजलि दें",
          change_language: "भाषा चुनें",
          home_btn: "./home_hin.svg",
          tribute_btn: "./tribute_hin.svg",
          lang_btn: "./lang_hin.svg",
          digital_jyot: "डिजिटल ज्योत",
          tribute_garden: "डिजिटल ज्योति : शहीदों को श्रद्धांजलि ",
          digital_intro:
            "अपनी श्रद्धांजलि अर्पित करके सेंट्रल पार्क, कनॉट प्लेस में डिजिटल ज्योति प्रज्ज्वलित करें। ",
          instructions: "नीचे दिए गए स्टेप अपनाएँ",
          step1: "स्टेप 1 : ",
          step2: "स्टेप 2 : ",
          step3: "स्टेप 3 : ",
          step4: "स्टेप 4 : ",
          step1_inst: "अपनी फ़ोटो क्लिक करें ",
          step2_inst: "अपने विवरण डालें",
          step3_inst: "एक संदेश सेलेक्ट करें ",
          step4_inst: "श्रद्धांजलि दें  ",
          note_head: "नोट : ",
          note: "आपकी श्रद्धांजलि सेंट्रल पार्क (कनॉट प्लेस) में लगी एलईडी स्क्रीन पर नज़र आएगी और आकाश में डिजिटल मशाल प्रज्ज्वलित होगी  ईमेल/ व्हाट्सअप के माध्यम से आपकी श्रद्धांजलि की वीडियो रिकॉर्डिंग आपको प्राप्त होगी।",
          about_desc1:
            "असंख्य स्वतंत्रता सेनानियों के बलिदान से ही हमारी आजादी संभव हुई है। जिन्होंने हमारे भविष्य निर्माण के लिए अपना वर्तमान दांव पर लगा दिया। कृतज्ञ राष्ट्र उनके साहस और बलिदान को नमन करते हुए सभी स्वतंत्रता सेनानियों को विनम्र श्रद्धांजलि देता है।",
          about_desc2:
            "भारत की राजधानी दिल्ली के  केंद्र में, कनॉट प्लेस के सेंट्रल पार्क में स्थापित डिजिटल ज्योत स्वतंत्रता के उस प्रकाश का प्रतीक है जो हमारे जीवन को रोशन करता है। यह डिजिटल ज्योत हमारे स्वतंत्रता सेनानियों और शहीदों के साहस और दृढ़ संकल्प से प्रेरित धातु के बने पुष्पों से घिरा हुआ है। आशा और सकारात्मकता के विचारों से प्रेरित ये पुष्प हमें अपनी प्रकृति से जोड़ते हैं।",
          about_desc3:
            "आज जब हम स्वतंत्रता के 75 वर्ष पूरे होने के उपलक्ष्य में आजादी का अमृत महोत्सव मना रहे हैं, इस अवसर पर डिजिटल ज्योत हमें याद दिलाता है कि व्यक्ति रहे या नहीं, उसके विचार हमेशा जीवित रहते हैं।",
          about_desc4:
            "आज़ादी के अमर सेनानियों को श्रद्धांजलि देने के लिए इन साधारण स्टेप को अपनाएँ - ",
          about_desc5:
            "सेंट्रल पार्क, कनॉट प्लेस, दिल्ली में स्थापित एलईडी स्क्रीन पर नज़र आने से पहले आपकी श्रद्धांजलि अन्य लोगों के साथ कतारबद्ध होगी और आपका नम्बर आने पर आकाश में डिजिटल ज्योत को रोशन करेगी।",
          about_desc6:
            "इसके बाद आपकी श्रद्धांजलि की एक वीडियो आपसे साझा की जाएगी। ",
          about_desc7:
            "कृपया ध्यान रखें - एलईडी स्क्रीन की दृश्यता को ध्यान में रखते हुए उसे सूर्यास्त के बाद चालू किया जाता है, हालाँकि श्रद्धांजलि संदेश कभी भी, किसी भी स्थान से भेजे जा सकते हैं।",

          live: "लाइव",
          about: "विषय में",
          tribute_paid_so_far: "अब तक दी  गई श्रद्धांजलि",
          submit: "सबमिट",
          name: "आपका नाम",
          email: "ईमेल आईडी",
          message: "संदेश चुनें",
          phone: "फोन नंबर",
          upload_image: "तस्वीर अपलोड करें",
          tribute_number: "आपके द्वारा दी गई श्रद्धांजलि संख्या : ",
          current_tribute_number: "वर्तमान श्रद्धांजलि संख्या : ",
          quote: [
            {
              id: 6,
              message:
                "हमारे भविष्य निर्माण के लिए उन्होंने अपने वर्तमान का बलिदान किया। कृतज्ञ राष्ट्र आज उनके प्रति आभार प्रकट करता है।",
            },
            {
              id: 7,
              message:
                "राष्ट्र की स्वतंत्रता में अपना योगदान देने वाले सभी सेनानियों को हम नमन करते हैं। हम उन्हें कभी भुला नहीं सकेंगे! ",
            },
            {
              id: 8,
              message:
                "हमारी स्वतंत्रता के लिए संघर्ष करने वाले, अपने जीवन का बलिदान देने वाले सभी स्वतंत्रता सेनानियों को स्वतंत्र राष्ट्र याद करता है। हम कृतज्ञ हैं। ",
            },
            {
              id: 9,
              message:
                "व्यक्ति रहे, या न रहे, उनके विचार सदा जीवित रहते हैं। स्वतंत्रता अक्षुण्ण बनी रहे!",
            },
            {
              id: 10,
              message:
                "सभी स्वतंत्रता सेनानियों को हम नमन करते हैं, जिन्होंने हमें राष्ट्र प्रथम और सर्वोपरि का पाठ पढ़ाया।",
            },
          ],
          thankyou: "धन्यवाद",
          thankyou_message:
            " इस अभियान में भाग लेने के लिए । ईमेल के माध्यम से आपकी श्रद्धांजलि की वीडियो रिकॉर्डिंग आपको प्राप्त होगी।",
        },
      },
      // as: {
      //   translations: {
      //     lang: "Assames",
      //     home: "ঘৰ",
      //     click_to_pay_tribute: "এতিয়ালৈকে শ্ৰদ্ধাঞ্জলি জ্ঞাপন কৰা হৈছে",
      //     click_to_change_lang: "ভাষা সলনি কৰিবলৈ ক্লিক কৰক",
      //     pay_tribute: "শ্ৰদ্ধাঞ্জলি জ্ঞাপন কৰক",
      //     change_language: "ভাষা পৰিবৰ্তন কৰক",
      //     home_btn: "./home_act.svg",
      //     tribute_btn: "./tribute.svg",
      //     lang_btn: "./language.svg",
      //     digital_jyot: "ডিজিটেল জ্যোত ",
      //     tribute_garden: "ডিজিটেল জ্যোত : শ্বহীদসকললৈ শ্ৰদ্ধাঞ্জলি ",
      //     digital_intro:
      //       "আপোনাৰ শ্ৰদ্ধাঞ্জলি জ্ঞাপন কৰি নতুন দিল্লীৰ কনাৰ্ক প্লেছৰ চেণ্ট্ৰেল পাৰ্কত এটা ডিজিটেল জ্যোত জ্বলাই দিয়ক।",
      //     instructions: "তলৰ পদক্ষেপসমূহ অনুসৰণ কৰক",
      //     step1: "পদক্ষেপ 1 : ",
      //     step2: "পদক্ষেপ 2 : ",
      //     step3: "পদক্ষেপ 3 : ",
      //     step4: "পদক্ষেপ 4 : ",
      //     step1_inst: "আপোনাৰ ছবি ক্লিক কৰক",
      //     step2_inst: "আপোনাৰ বিৱৰণ দাখিল কৰক",
      //     step3_inst: "আপোনাৰ বাৰ্তা বাছনি কৰক",
      //     step4_inst: "আপোনাৰ শ্ৰদ্ধাঞ্জলি জ্ঞাপন কৰক",
      //     note_head: "টোকা : ",
      //     note: "আপোনাৰ শ্ৰদ্ধাঞ্জলি চেণ্ট্ৰেল পাৰ্কৰ লেড স্ক্ৰীণত (কনাৰ্ক প্লেচ) ফ্লেছ হ'ব আৰু আকাশত ডিজিটেল শিখা জ্বলাব । টোকা: আপোনাৰ শ্ৰদ্ধাঞ্জলিৰ এটা ভিডিঅ' ৰেকৰ্ডিং ইমেইল/	হোৱাটছএপ জৰিয়তে শ্বেয়াৰ কৰা হ'ব",

      //     live: "বাতৰি",
      //     about: "About",
      //     tribute_paid_so_far: "এতিয়ালৈকে শ্ৰদ্ধাঞ্জলি জ্ঞাপন কৰা হৈছে",
      //     submit: "দাখিল কৰা",
      //     name: "আপোনাৰ নাম",
      //     email: "ই-মেইল আইডি",
      //     phone: "ফোন নম্বৰ",
      //     message: "বাৰ্তা নিৰ্বাচন কৰক",
      //     upload_image: "ছবি আপলোড কৰক ",
      //     tribute_number: "আপোনাৰ শ্ৰদ্ধাঞ্জলি নম্বৰ : ",
      //     current_tribute_number: "বৰ্তমান শ্ৰদ্ধাঞ্জলি নম্বৰ : ",
      //     quote: [
      //       {
      //         id: 1,
      //         message:
      //           "তেওঁলোকে আমাৰ কাইলৈৰ বাবে তেওঁলোকৰ আজিৰ দিনটো ত্যাগ কৰিছিল। এজন কৃতজ্ঞ ৰাষ্ট্ৰই কয় ধন্যবাদ!",
      //       },
      //       {
      //         id: 2,
      //         message:
      //           "আমি সেই সকলোকে মনত ৰাখিছোঁ যিয়ে আমাৰ স্বাধীনতা সম্ভৱ কৰি তুলিছিল। আমি কেতিয়াও পাহৰিব নোৱাৰিম!",
      //       },
      //       {
      //         id: 3,
      //         message:
      //           "এখন ৰাষ্ট্ৰই আমাৰ স্বাধীনতানিশ্চিত কৰা, ত্যাগ কৰা আৰু নিশ্চিত কৰা সকলোকে স্মৰণ কৰে। আমি কৃতজ্ঞ",
      //       },
      //       {
      //         id: 4,
      //         message:
      //           "এজন ব্যক্তি আৰু নাথাকিব পাৰে কিন্তু তেওঁলোকৰ ধাৰণাবোৰ জীয়াই থাকে। স্বাধীনতা দীৰ্ঘজীৱী হওক!",
      //       },
      //       {
      //         id: 5,
      //         message:
      //           "আমি আমাৰ স্বাধীনতা সংগ্ৰামীসকলক অভিবাদন জনাইছো যিয়ে আমাক ৰাষ্ট্ৰ প্ৰথম আৰু সদায় প্ৰথমশিকাইছিল।",
      //       },
      //     ],

      //     thankyou: "ধন্যবাদ।",
      //     thankyou_message:
      //       " এই অভিযানৰ অংশ হোৱাৰ বাবে আপোনাক  আপুনি ইমেইলযোগে আপোনাৰ শ্ৰদ্ধাঞ্জলিৰ ভিডিঅ' ৰেকৰ্ডিং পাব।",
      //   },
      // },
      ban: {
        translations: {
          lang: "Bangla",
          home: "ঘর",
          click_to_pay_tribute: "শ্রদ্ধাঞ্জলি অর্পন করতে ক্লিক করুন",
          click_to_change_lang: "ভাষা পরিবর্তন করতে ক্লিক করুন ",
          pay_tribute: "শ্রদ্ধা নিবেদন",
          change_language: "ভাষা পরিবর্তন করুন",
          home_btn: "./home_act.svg",
          tribute_btn: "./tribute.svg",
          lang_btn: "./language.svg",
          digital_jyot: "ডিজিটাল জ্যোতি",
          tribute_garden: "ডিজিটাল জ্যোতি : শহীদদের প্রতি শ্রদ্ধাঞ্জলি",
          digital_intro:
            "আপনার শ্রদ্ধাঞ্জলি নিবেদন করে, নতুন দিল্লির কনট প্লেসের সেন্ট্রাল পার্কে একটি ডিজিটাল জ্যোতি জ্বালান।",
          instructions: "নিচের  ধাপ গুলি অনুসরণ করুন",
          step1: "স্টেপ 1 : ",
          step2: "স্টেপ 2 : ",
          step3: "স্টেপ 3 : ",
          step4: "স্টেপ 4 : ",
          step1_inst: "আপনার ছবি তুলুন",
          step2_inst: "আপনার বিবরণ প্রবেশ করান",
          step3_inst: "আপনার বার্তা নির্বাচন করুন",
          step4_inst: "আপনার শ্রদ্ধাঞ্জলি নিবেদন করুন",
          note_head: "দ্রষ্টব্য : ",
          note: "আপনার শ্রদ্ধাঞ্জলি সেন্ট্রাল পার্কে (কনট প্লেস) LED স্ক্রিনে ফ্ল্যাশ হবে এবং আকাশে ডিজিটাল শিখা প্রজ্জ্বলিত হবে. আপনার শ্রদ্ধাঞ্জলির ভিডিও রেকর্ডিং ইমেল/এসএমএসের মাধ্যমে শেয়ার করা হবে.",

          live: "লাইভ দেখান",
          about: "সম্পর্কিত",
          tribute_paid_so_far: "এপর্যন্ত শ্রদ্ধাঞ্জলি অর্পন করা হয়েছে",
          submit: "জমা করা",
          name: "আপনার নাম",
          email: "ই-মেইল আইডি",
          phone: "ফোন নম্বর",
          message: "বার্তা",
          upload_image: "ছবি আপলোড করুন",
          tribute_number: "আপনার শ্রদ্ধাঞ্জলি বা ট্রিবিউট নম্বর : ",
          current_tribute_number: "বর্তমান শ্রদ্ধাঞ্জলি বা ট্রিবিউট  নম্বর : ",
          quote: [
            {
              id: 1,
              message:
                "তারা আমাদের আগামীকালের জন্য নিজেদের বর্তমান উৎসর্গ করেছেন। কৃতজ্ঞ জাতি তাদের বলছে, ধন্যবাদ!",
            },
            {
              id: 2,
              message:
                "আমরা সেই সকলকে স্মরণ করি যাদের জন্য আমাদের স্বাধীনতা এসেছে। আমরা তাদের কখনই ভুলব না!",
            },
            {
              id: 3,
              message:
                "জাতি সেই সকলকে স্মরণ করে যারা লড়াই করেছেন, বলিদান দিয়েছেন এবং আমাদের স্বাধীনতাকে সম্ভব করেছেন। আমরা কৃতজ্ঞ। ",
            },
            {
              id: 4,
              message:
                "একজন ব্যক্তি চিরকাল নাও থাকতে পারেন, কিন্তু তাদের চেতনা বেঁচে থাকে। স্বাধীনতা দীর্ঘজীবী হোক !",
            },
            {
              id: 5,
              message:
                "আমরা আমাদের সকল স্বাধীনতা সংগ্রামীদের কুর্নিশ জানাই যারা আমাদের শিখিয়েছিলেন যে দেশ সবার আগে, সব সময় আগে।",
            },
          ],

          thankyou: "ধন্যবাদ",
          thankyou_message:
            " এই প্রচারাভিযানের  অংশ হওয়ার জন্য। আপনি ইমেলের মাধ্যমে আপনার শ্রদ্ধাঞ্জলির ভিডিও রেকর্ডিং পাবেন।",
        },
      },
      // kan: {
      //   translations: {
      //     lang: "Kannada",
      //     home: "ಮನೆ",
      //     click_to_pay_tribute: "ಗೌರವ ಸಲ್ಲಿಸಲು ಕ್ಲಿಕ್ ಮಾಡಿ",
      //     click_to_change_lang: "ಭಾಷೆಯನ್ನು ಬದಲಿಸಲು ಕ್ಲಿಕ್ ಮಾಡಿ",
      //     pay_tribute: "ಗೌರವ ಸಲ್ಲಿಸುತ್ತಾರೆ",
      //     change_language: "ಭಾಷೆ ಬದಲಾವಣೆ",
      //     home_btn: "./home_act.svg",
      //     tribute_btn: "./tribute.svg",
      //     lang_btn: "./language.svg",
      //     digital_jyot: "ಡಿಜಿಟಲ್‌ ಜ್ಯೋತ್‌",
      //     tribute_garden: "ಡಿಜಿಟಲ್‌ ಜ್ಯೋತ್‌ : ಹುತಾತ್ಮರಿಗೆ ಗೌರವ",
      //     digital_intro:
      //       "ನೀವು ಗೌರವವನ್ನು ಅರ್ಪಿಸುವ ಮೂಲಕನವದೆಹಲಿಯ ಕನಾಟ್‌ ಪ್ಲೇಸ್‌ನಲ್ಲಿರುವ ಸೆಂಟ್ರಲ್‌ ಪಾರ್ಕಿನಲ್ಲಿ ಡಿಜಿಟಲ್‌ ಜ್ಯೋತಿಯನ್ನು ಬೆಳಗಿಸಿ.",
      //     instructions: "ಅದಕ್ಕಾಗಿ ಹೀಗೆ ಮಾಡಿ",
      //     step1: " 1 : ",
      //     step2: " 2 : ",
      //     step3: " 3 : ",
      //     step4: " 4 : ",
      //     step1_inst: "ನಿಮ್ಮ ಫೋಟೋ ಕ್ಲಿಕ್‌ ಮಾಡಿ",
      //     step2_inst: "ನಿಮ್ಮ ವಿವರಗಳನ್ನು ನಮೂದಿಸಿ",
      //     step3_inst: "ಸಂದೇಶವನ್ನು ಆಯ್ಕೆ ಮಾಡಿ",
      //     step4_inst: "ಗೌರವವನ್ನು ಅರ್ಪಿಸಿ",
      //     note_head: "ನಿಮ್ಮ : ",
      //     note: "ನಿಮ್ಮ ಸಂದೇಶವು ಕನಾಟ್‌ ಪ್ಲೇಸ್‌ನ ಸೆಂಟ್ರಲ್‌ ಪಾರ್ಕಿನಲ್ಲಿರುವ ಎಲ್‌.ಇ.ಡಿ ಪರದೆಯ ಮೇಲೆ ಮೂಡಿ ಬರಲಿದೆ ಮತ್ತು ಆಕಾಶದಲ್ಲಿ ಡಿಜಿಟಲ್‌ ಜ್ಯೋತಿಯನ್ನು ಬೆಳಗಲಿದೆ. ನಿಮ್ಮ ಗೌರವಾರ್ಪಣೆಯ ವೀಡಿಯೋ ರೆಕಾರ್ಡಿಂಗನ್ನು ಈಮೇಲ್‌/	ನುಡಿಗಟ್ಟು ಮೂಲಕ ಹಂಚಿಕೊಳ್ಳಲಾಗುವುದು.",

      //     live: "ಬದುಕುತ್ತಾರೆ",
      //     about: "About",
      //     tribute_paid_so_far: "ಇಲ್ಲಿಯವರೆಗೆ ಸಲ್ಲಿಸದ ಶ್ರದ್ಧಾಂಜಲಿಗಳು",
      //     submit: "ಸಲ್ಲಿಸು",
      //     name: "ನಿಮ್ಮ ಹೆಸರು",
      //     phone: "ದೂರವಾಣಿ ಸಂಖ್ಯೆ",
      //     email: "ಇ-ಮೇಲ್ ಐಡಿ",
      //     message: "ಸಂದೇಶವನ್ನು ಆಯ್ಕೆಮಾಡಿ",
      //     upload_image: "ಚಿತ್ರವನ್ನು ಅಪ್‌ಲೋಡ್ ಮಾಡಿ",
      //     tribute_number: "ನಿಮ್ಮ ಗೌರವಾರ್ಪಣೆ ಸಂಖ್ಯೆ : ",
      //     current_tribute_number: "ಪ್ರಚಲಿತ ಗೌರವಾರ್ಪಣೆ ಸಂಖ್ಯೆ : ",
      //     quote: [
      //       {
      //         id: 1,
      //         message:
      //           "ಅವರು ನಮ್ಮ ನಾಳೆಗಾಗಿ ಇಂದು ತಮ್ಮ ತ್ಯಾಗ ಮಾಡಿದರು. ಕೃತಜ್ಞರಾಷ್ಟ್ರವು ಧನ್ಯವಾದಗಳು ಎಂದು ಹೇಳುತ್ತದೆ!",
      //       },
      //       {
      //         id: 2,
      //         message:
      //           "ನಮ್ಮ ಸ್ವಾತಂತ್ರ್ಯವನ್ನು ಸಾಧ್ಯವಾಗಿಸಿದ ಎಲ್ಲರನ್ನೂ ನಾವು ನೆನಪಿಸಿಕೊಳ್ಳುತ್ತೇವೆ. ನಾವು ಎಂದಿಗೂ ಮರೆಯುವುದಿಲ್ಲ!",
      //       },
      //       {
      //         id: 3,
      //         message:
      //           "ಒಂದು ರಾಷ್ಟ್ರವು ನಮ್ಮ ಸ್ವಾತಂತ್ರ್ಯವನ್ನು ಹೋರಾಡಿದ, ತ್ಯಾಗ ಮಾಡಿದ ಮತ್ತು ಖಚಿತಪಡಿಸಿದ ಎಲ್ಲರನ್ನೂ ನೆನಪಿಸಿಕೊಳ್ಳುತ್ತದೆ. ನಾವು ಕೃತಜ್ಞರಾಗಿದ್ದೇವೆ",
      //       },
      //       {
      //         id: 4,
      //         message:
      //           "ಒಬ್ಬ ವ್ಯಕ್ತಿಯು ಇನ್ನು ಮುಂದೆ ಇಲ್ಲದಿರಬಹುದು ಆದರೆ ಅವರ ಆಲೋಚನೆಗಳು ಬದುಕುತ್ತವೆ. ಸ್ವಾತಂತ್ರ್ಯ ದೀರ್ಘಾಯುಷಿ!",
      //       },
      //       {
      //         id: 5,
      //         message:
      //           "ನಮಗೆ ಮೊದಲು ಮತ್ತು ಯಾವಾಗಲೂ ಮೊದಲು ರಾಷ್ಟ್ರವನ್ನು ಕಲಿಸಿದ ನಮ್ಮ ಸ್ವಾತಂತ್ರ್ಯ ಹೋರಾಟಗಾರರಿಗೆ ನಾವು ವಂದಿಸುತ್ತೇವೆ.",
      //       },
      //     ],

      //     thankyou: "ಧನ್ಯವಾದಗಳು",
      //     thankyou_message:
      //       " ಈ ಕಾರ್ಯಕ್ರಮದ ಭಾಗವಾಗಿದ್ದಕ್ಕೆ. ನಿಮ್ಮ ಗೌರವಾರ್ಪಣೆಯ ವೀಡಿಯೋ ರೆಕಾರ್ಡಿಂಗನ್ನು ಈಮೇಲ್‌ ಮೂಲಕ ನಿಮಗೆ ಕಳುಹಿಸಲಾಗುವುದು.",
      //   },
      // },
      mal: {
        translations: {
          lang: "Malyalam",
          home: "വീട്",
          click_to_pay_tribute: "ആദരാഞ്ജലി അർപ്പിക്കാൻ ക്ലിക്ക് ചെയ്യുക",
          click_to_change_lang: "ഭാഷ മാറ്റാൻ ക്ലിക്ക് ചെയ്യുക ",
          pay_tribute: "ആദരാഞ്ജലി അർപ്പിക്കുക",
          change_language: "ഭാഷ മാറ്റുക",
          home_btn: "./home_act.svg",
          tribute_btn: "./tribute.svg",
          lang_btn: "./language.svg",
          digital_jyot: "ഡിജിറ്റൽ ജ്യോതി",
          tribute_garden: "ഡിജിറ്റൽ ജ്യോതി: രക്തസാക്ഷികൾക്ക് ആദരാഞ്ജലി",
          digital_intro:
            "നിങ്ങളുടെ ആദരാഞ്ജലികൾ അർപ്പിച്ചുകൊണ്ട് ന്യൂഡൽഹിയിലെ കൊണാട്ട് പ്ലേസിലെ സെൻട്രൽ പാർക്കിൽ ഒരു ഡിജിറ്റൽ ജ്യോതി പ്രകാശിപ്പിക്കുക.",
          instructions: "ചുവടെയുള്ള ഘട്ടങ്ങൾ പിന്തുടരുക",
          step1: "ഘട്ടം 1 : ",
          step2: "ഘട്ടം 2 : ",
          step3: "ഘട്ടം 3 : ",
          step4: "ഘട്ടം 4 : ",
          step1_inst: "നിങ്ങളുടെ ചിത്രം എടുക്കുക",
          step2_inst: "നിങ്ങളുടെ വിശദാംശങ്ങൾ നൽകുക",
          step3_inst: "നിങ്ങളുടെ സന്ദേശം തിരഞ്ഞെടുക്കുക",
          step4_inst: "നിങ്ങളുടെ ആദരാഞ്ജലി അർപ്പിക്കുക",
          note_head: "ശ്രദ്ധിക്കുക : ",
          note: "സെൻട്രൽ പാർക്കിലെ (കൊണാട്ട് പ്ലേസ്) എൽഇഡി സ്ക്രീനിൽ നിങ്ങളുടെ ആദരാഞ്ജലി മിന്നുകയും ആകാശത്ത് ഡിജിറ്റൽ ജ്വാല തെളിയുകയും ചെയ്യും. നിങ്ങളുടെ ആദരാഞ്ജലിയുടെ ഒരു വീഡിയോ റെക്കോർഡിംഗ് ഇമെയിൽ/എസ്എംഎസ് വഴി പങ്കിടും.",

          live: "ജീവിക്കുക",
          about: "കുറിച്ച്",
          tribute_paid_so_far: "ഇതുവരെ അർപ്പിച്ച ആദരാഞ്ജലികൾ ",
          submit: "സമർപ്പിക്കുക",
          name: "നിങ്ങളുടെ പേര്",
          phone: "ഫോൺ നമ്പർ",
          email: "ഇമെയിൽ ഐഡി",
          message: "സന്ദേശം",
          upload_image: "ചിത്രം അപ്‌ലോഡ് ചെയ്യുക",
          tribute_number: "നിങ്ങളുടെ ആദരാഞ്ജലി നമ്പർ : ",
          current_tribute_number: "നിലവിലെ ആദരാഞ്ജലി നമ്പർ : ",
          quote: [
            {
              id: 1,
              message:
                "നമ്മുടെ നാളേക്കുവേണ്ടി അവർ ഇന്ന് ത്യാഗം ചെയ്തു. നന്ദിയുള്ള ഒരു രാജ്യം നന്ദി പറയുന്നു!",
            },
            {
              id: 2,
              message:
                "നമ്മുടെ സ്വാതന്ത്ര്യം സാധ്യമാക്കിയ എല്ലാവരെയും നാം ഓർക്കുന്നു. നമ്മൾ ഒരിക്കലും മറക്കില്ല!",
            },
            {
              id: 3,
              message:
                "നമ്മുടെ സ്വാതന്ത്ര്യം ഉറപ്പാക്കുകയും പോരാടുകയും ത്യാഗം ചെയ്യുകയും ചെയ്ത എല്ലാവരെയും ഒരു രാജ്യം ഓർക്കുന്നു. ഞങ്ങൾ നന്ദിയുള്ളവരാണ്",
            },
            {
              id: 4,
              message:
                "ഒരു വ്യക്തി ഇനി അവിടെ ഉണ്ടായിരിക്കില്ല, പക്ഷേ അവരുടെ ആശയങ്ങൾ ജീവിക്കുന്നു. സ്വാതന്ത്ര്യം നീണാൾ വാഴട്ടെ! ",
            },
            {
              id: 5,
              message:
                "ആദ്യം രാഷ്ട്രം എന്ന് പഠിപ്പിച്ച നമ്മുടെ സ്വാതന്ത്ര്യ സമര സേനാനികളെ ഞങ്ങൾ അഭിവാദ്യം ചെയ്യുന്നു.",
            },
          ],

          thankyou: "നന്ദി",
          thankyou_message:
            " ഈ കാമ്പെയ്‌നിന്റെ ഭാഗമായതിന്.  നിങ്ങളുടെ ആദരാഞ്ജലിയുടെ വീഡിയോ റെക്കോർഡിംഗ് ഇമെയിൽ വഴി നിങ്ങൾക്ക് ലഭിക്കും.",
        },
      },
      mar: {
        translations: {
          lang: "Marathi",
          home: "घर",
          click_to_pay_tribute: "आदरांजली वाहण्यासाठी क्लिक करा",
          click_to_change_lang: "भाषा बदलण्यासाठी क्लिक करा ",
          pay_tribute: "श्रद्धांजली वाहण्यासाठी",
          change_language: "भाषा बदलण्यासाठी",
          home_btn: "./home_act.svg",
          tribute_btn: "./tribute.svg",
          lang_btn: "./language.svg",
          digital_jyot: "डिझिटल ज्योत",
          tribute_garden: "डिझिटल ज्योत - हुतात्म्यांना आदरांजली",
          digital_intro:
            "तुमच्याकडून आदरांजली अर्पण करण्यासाठी कॅनॉट प्लेस सेंट्रल पार्क, नवी दिल्ली येथे डिझिटल ज्योत प्रज्वलित करा",
          instructions: "खालील टप्प्यांचे अनुसरण करा",
          step1: "१ : ",
          step2: "२ : ",
          step3: "३ : ",
          step4: "४ : ",
          step1_inst: "तुमचा फोटो क्लिक करा",
          step2_inst: "तुमचा तपशील नोंदवा",
          step3_inst: " तुमचा संदेश निवडा",
          step4_inst: "तुमची आदरांजली अर्पण करा",
          note_head: "टीप : ",
          note: "तुमची आदरांजली मोठ्या एलइडी स्क्रीनवर सेंट्रल पार्क, कॅनॉट प्लेस येथे दाखवली जाईल आणि आकाशांत डिझिटल ज्योत प्रज्वलित केली जाईल.तुमच्या आदरांजलीचे व्हीडीओ रेकॉर्डिंग ई-मेल/एसमेस नी सामायिक केला जाईल.",

          live: "राहतात",
          about: "बद्दल",
          tribute_paid_so_far: "आतापर्यंत वाहिलेल्या आदरांजली",
          submit: "प्रस्तुत करणे",
          name: "तुमचे नाव",
          phone: "फोन नंबर",
          email: "ई-मेल पत्ता",
          message: "संदेश",
          upload_image: "फोटो अपलोड करा",
          tribute_number: "तुमचा आदरांजली क्रमांक : ",
          current_tribute_number: "वर्तमान आदरांजली क्रमांक : ",
          quote: [
            {
              id: 1,
              message:
                "त्यांनी आपल्या भैवितव्यासाठी त्यांच्या वर्तमानाचा त्याग केला. एक कृतज्ञ देश त्यांच्या प्रती आभार प्रकट करतो!",
            },
            {
              id: 2,
              message:
                "ज्यांनी आपले स्वातंत्र्य शक्य केले त्या सर्वांचे आपण स्मरण करतो. आम्ही कधीच विसरणार नाही!",
            },
            {
              id: 3,
              message:
                "जे लढले, ज्यांनी बलिदान दिले आणि आपल्या स्वातंत्र्य मिळेल याची खात्री करून दिली त्या सर्वांचे एक राष्ट्र स्मरण करते. आम्ही आभारी आहोत",
            },
            {
              id: 4,
              message:
                "एखादी व्यक्ती मेली, तरी त्याच्या कल्पना जिवंत राहतात. स्वातंत्र्य चिरायू होवो!",
            },
            {
              id: 5,
              message:
                "आम्ही आमच्या स्वातंत्र्यसैनिकांना सलाम करतो ज्यांनी आम्हाला राष्ट्र प्रथम आणि नेहमीच प्रथम ही शिकवण दिली.",
            },
          ],

          thankyou: "",
          thankyou_message:
            " या मोहिमेत सहभागी झाल्या बद्दल धन्यवाद. ई-मेल द्वारे आपल्याला आपल्या आदरांजलीचे रेकॉर्डिंग मीळेल .",
        },
      },
      // od: {
      //   translations: {
      //     lang: "Odiya",
      //     home: "ଘର",
      //     click_to_pay_tribute: "ଶ୍ରଦ୍ଧାଞ୍ଜଳି ଦେବାକୁ କ୍ଲିକ୍ କରନ୍ତୁ",
      //     click_to_change_lang: "ଭାଷା ପରିବର୍ତ୍ତନ କରିବାକୁ କ୍ଲିକ୍ କରନ୍ତୁ",
      //     pay_tribute: "ଶ୍ରଦ୍ଧାଞ୍ଜଳି ଦିଅ |",
      //     change_language: "ଭାଷା ପରିବର୍ତ୍ତନ କରନ୍ତୁ |",
      //     home_btn: "./home_act.svg",
      //     tribute_btn: "./tribute.svg",
      //     lang_btn: "./language.svg",
      //     digital_jyot: "Digital Jyot",
      //     tribute_garden: "ଡିଜିଟାଲ୍ ଜ୍ୟୋଟ୍: ଶହୀଦମାନଙ୍କୁ ଶ୍ରଦ୍ଧାଞ୍ଜଳି |",
      //     digital_intro:
      //       "ତୁମର ଶ୍ରଦ୍ଧା paying ୍ଜଳି ଦେଇ ନୂଆଦିଲ୍ଲୀ, କେନେଟ୍ ସ୍ଥାନରେ ଥିବା କେନ୍ଦ୍ରୀୟ ପାର୍କରେ ଏକ ଡିଜିଟାଲ୍ ଜ୍ୟୋଟ୍ ଆଲୋକିତ କର |",
      //     instructions: "ନିମ୍ନରେ ପଦକ୍ଷେପଗୁଡିକ ଅନୁସରଣ କରନ୍ତୁ |",
      //     step1: "ପଦାଙ୍କ 1 : ",
      //     step2: "ପଦାଙ୍କ 2 : ",
      //     step3: "ପଦାଙ୍କ 3 : ",
      //     step4: "ପଦାଙ୍କ 4 : ",
      //     step1_inst: "ତୁମର ଛବି କ୍ଲିକ୍ କର | ",
      //     step2_inst: "ଆପଣଙ୍କର ବିବରଣୀ ପ୍ରବେଶ କରନ୍ତୁ |",
      //     step3_inst: "ଆପଣଙ୍କର ବାର୍ତ୍ତା ଚୟନ କରନ୍ତୁ |",
      //     step4_inst: "	ତୁମର ଶ୍ରଦ୍ଧାଞ୍ଜଳି ଦିଅ |",
      //     note_head: "ଧ୍ୟାନ ଦିଅନ୍ତୁ : ",
      //     note: "ତୁମର ଶ୍ରଦ୍ଧାଞ୍ଜଳି ସେଣ୍ଟ୍ରାଲ ପାର୍କର ଏଲଇଡି ସ୍କ୍ରିନରେ ଫ୍ଲାସ୍ ହେବ ଏବଂ ଆକାଶରେ ଡିଜିଟାଲ୍ ନିଆଁକୁ ଆଲୋକିତ କରିବ |  ଆପଣଙ୍କ ଶ୍ରଦ୍ଧାଞ୍ଜଳିର ଏକ ଭିଡିଓ ରେକର୍ଡିଂ ଇମେଲ /ହ୍ୱାଟସଆପ୍ ମାଧ୍ୟମରେ ସେୟାର କରାଯିବ | |",

      //     live: "ଜୀବନ୍ତ",
      //     about: "ବିଷୟରେ",
      //     tribute_paid_so_far: "ଶ୍ରଦ୍ଧାଞ୍ଜଳି ଏପର୍ଯ୍ୟନ୍ତ ପ୍ରଦାନ କରାଯାଇଛି |",
      //     submit: "ଦାଖଲ କରନ୍ତୁ",
      //     name: "ତୁମର ନାମ",
      //     phone: "ଫୋନ୍ ନମ୍ଵର୍",
      //     email: "ଇ-ମେଲ୍ ଆଇଡି ",
      //     message: "ବାର୍ତ୍ତା ଚୟନ କରନ୍ତୁ |",
      //     upload_image: "ପ୍ରତିଛବି ଅପଲୋଡ୍ କରନ୍ତୁ |",
      //     tribute_number: "ତୁମର ଶ୍ରଦ୍ଧାଞ୍ଜଳି ସଂଖ୍ୟା : ",
      //     current_tribute_number: "ସାମ୍ପ୍ରତିକ ଶ୍ରଦ୍ଧାଞ୍ଜଳି ସଂଖ୍ୟା : ",
      //     quote: [
      //       {
      //         id: 1,
      //         message:
      //           " ଧନ୍ୟବାଦ!	ସେମାନେ ଆମ ଆସନ୍ତାକାଲି ପାଇଁ ସେମାନଙ୍କର ଆଜି ବଳିଦାନ ଦେଇଛନ୍ତି । ଏକ କୃତଜ୍ଞ ରାଷ୍ଟ୍ର ଧନ୍ୟବାଦ କୁହନ୍ତି!",
      //       },
      //       {
      //         id: 2,
      //         message:
      //           "ଯେଉଁମାନେ ଆମର ସ୍ୱାଧୀନତାକୁ ସମ୍ଭବ କରିଥିଲେ ସେମାନଙ୍କ ବିଷୟରେ ଆମେ ମନେ ରଖିଛୁ । ଆମେ କଦାପି ଭୁଲିପାରିବୁ ନାହିଁ!",
      //       },
      //       {
      //         id: 3,
      //         message:
      //           "ଯେଉଁମାନେ ଆମର ସ୍ୱାଧୀନତା ସହିତ ଲଢିଥିଲେ, ବଳିଦାନ ଏବଂ ସୁନିଶ୍ଚିତ କରିଥିଲେ, ସେମାନଙ୍କ ପାଇଁ ଏକ ରାଷ୍ଟ୍ର ସ୍ମରଣ କରେ । ଆମେ କୃତଜ୍ଞ",
      //       },
      //       {
      //         id: 4,
      //         message:
      //           "ଜଣେ ବ୍ୟକ୍ତି ଆଉ ସେଠାରେ ନଥାଇପାରନ୍ତି କିନ୍ତୁ ସେମାନଙ୍କର ଧାରଣା ବଞ୍ଚିଥାଏ | ଦୀର୍ଘାୟୁ ସ୍ୱାଧୀନତା!",
      //       },
      //       {
      //         id: 5,
      //         message:
      //           "ଆମେ ଆମର ସ୍ୱାଧୀନତା ସଂଗ୍ରାମୀମାନଙ୍କୁ ସାଲ୍ୟୁଟ୍ କରୁଛୁ ଯେଉଁମାନେ ଆମକୁ ପ୍ରଥମେ ରାଷ୍ଟ୍ର ଶିଖାଇଥିଲେ ଏବଂ ସର୍ବଦା ପ୍ରଥମେ |",
      //       },
      //     ],

      //     thankyou: "ଧନ୍ୟବାଦ",
      //     thankyou_message:
      //       " ଏହି ଅଭିଯାନର ଅଂଶ ହୋଇଥିବାରୁ ଆପଣ ଇମେଲ ମାଧ୍ୟମରେ ଆପଣଙ୍କର ଶ୍ରଦ୍ଧାଞ୍ଜଳିର ଭିଡିଓ ରେକର୍ଡିଂ ପାଇବେ |",
      //   },
      // },
      // pun: {
      //   translations: {
      //     lang: "Punjabi",
      //     home: "ਘਰ",
      //     click_to_pay_tribute: "ਸ਼ਰਧਾਂਜਲੀ ਦੇਣ ਲਈ ਕਲਿੱਕ ਕਰੋ",
      //     click_to_change_lang: "ਭਾਸ਼ਾ ਬਦਲਣ ਲਈ ਕਲਿੱਕ ਕਰੋ",
      //     pay_tribute: "ਸ਼ਰਧਾਂਜਲੀ ਭੇਟ ਕਰੋ",
      //     change_language: "ਭਾਸ਼ਾ ਬਦਲੋ",
      //     home_btn: "./home_act.svg",
      //     tribute_btn: "./tribute.svg",
      //     lang_btn: "./language.svg",
      //     digital_jyot: "ਡਿਜੀਟਲ ਜੋਤ",
      //     tribute_garden: "ਡਿਜੀਟਲ ਜੋਤ : ਸ਼ਹੀਦਾਂ ਨੂੰ ਸ਼ਰਧਾਂਜਲੀ",
      //     digital_intro:
      //       "ਕੈਨੌਟ ਪਲੇਸ, ਨਵੀਂ ਦਿੱਲੀ ਦੇ ਸੈਂਟਰਲ ਪਾਰਕ ਵਿੱਚ ਆਪਣੀ ਸ਼ਰਧਾਂਜਲੀ ਦੇ ਕੇ ਇੱਕ ਡਿਜੀਟਲ ਜੋਤ ਜਗਾਓ।",
      //     instructions: "ਹੇਠਾਂ ਦਿੱਤੇ ਕਦਮਾਂ ਦੀ ਪਾਲਣਾ ਕਰੋ",
      //     step1: "ਕਦਮ 1 : ",
      //     step2: "ਕਦਮ 2 : ",
      //     step3: "ਕਦਮ 3 : ",
      //     step4: "ਕਦਮ 4 : ",
      //     step1_inst: "ਆਪਣੀ ਤਸਵੀਰ 'ਤੇ ਕਲਿੱਕ ਕਰੋ",
      //     step2_inst: "ਆਪਣੇ ਵੇਰਵੇ ਦਾਖਲ ਕਰੋ",
      //     step3_inst: "ਆਪਣੇ ਸੁਨੇਹੈ ਦੀ ਚੌਣ ਕਰੋ",
      //     step4_inst: "ਆਪਣੀ ਸ਼ਰਧਾਂਜਲੀ  ਭੇਟ ਕਰੋ",
      //     note_head: "ਨੋਟ : ",
      //     note: "ਤੁਹਾਡੀ ਸ਼ਰਧਾਂਜਲੀ ਸੈਂਟਰਲ ਪਾਰਕ (ਕਨਾਟ ਪਲੇਸ) ਵਿੱਚ LED ਸਕਰੀਨ ਤੇ ਫਲੈਸ਼ ਕਰੇਗੀ ਅਤੇ ਅਸਮਾਨ ਵਿੱਚ ਡਿਜੀਟਲ ਲਾਟ ਨੂੰ ਚਾਨਣਮਈ ਕਰੇਗੀ । ਤੁਹਾਡੀ ਸ਼ਰਧਾਂਜਲੀ ਦੀ  ਵੀਡੀਓ ਰਿਕਾਰਡਿੰਗ ਈਮੇਲ/	ਵਟਸਐੱਪ ਰਾਹੀਂ ਸਾਂਝੀ ਕੀਤੀ ਜਾਵੇਗੀ",

      //     live: "ਲਾਈਵ",
      //     about: "About",
      //     tribute_paid_so_far: "ਹੁਣ ਤੱਕ ਦਿੱਤੀਆਂ ਗਈਆਂ ਸ਼ਰਧਾਂਜਲੀਆਂ",
      //     submit: "ਜਮ੍ਹਾਂ ਕਰੋ",
      //     name: "ਤੁਹਾਡਾ ਨਾਮ",
      //     phone: "ਫੋਨ ਨੰਬਰ",
      //     email: "ਈਮੇਲ",
      //     message: "ਸੁਨੇਹਾ ਚੁਣੋ",
      //     upload_image: "ਇਮੇਜ (ਤਸਵੀਰ) ਅੱਪਲੋਡ ਕਰੋ",
      //     tribute_number: "ਤੁਹਾਡਾ  ਸ਼ਰਧਾਂਜਲੀ ਨੰਬਰ : ",
      //     current_tribute_number: "ਮੌਜੂਦਾ ਸ਼ਰਧਾਂਜਲੀ ਨੰਬਰ : ",
      //     quote: [
      //       {
      //         id: 1,
      //         message:
      //           "ਉਨ੍ਹਾਂ ਨੇ ਸਾਡੇ ਕੱਲ੍ਹ ਲਈ ਆਪਣਾ ਅੱਜ ਕੁਰਬਾਨ ਕਰ ਦਿੱਤਾ। ਇੱਕ ਸ਼ੁਕਰਗੁਜ਼ਾਰ ਰਾਸ਼ਟਰ ਕਹਿੰਦਾ ਹੈ ਤੁਹਾਡਾ ਧੰਨਵਾਦ!",
      //       },
      //       {
      //         id: 2,
      //         message:
      //           "ਅਸੀਂ ਉਨ੍ਹਾਂ ਸਾਰਿਆਂ ਨੂੰ ਯਾਦ ਕਰਦੇ ਹਾਂ ਜਿਨ੍ਹਾਂ ਨੇ ਸਾਡੀ ਆਜ਼ਾਦੀ ਨੂੰ ਸੰਭਵ ਬਣਾਇਆ। ਅਸੀਂ ਕਦੇ ਨਹੀਂ ਭੁੱਲਾਂਗੇ!",
      //       },
      //       {
      //         id: 3,
      //         message:
      //           "ਇੱਕ ਰਾਸ਼ਟਰ ਉਨ੍ਹਾਂ ਸਾਰਿਆਂ ਨੂੰ ਯਾਦ ਕਰਦਾ ਹੈ ਜਿਨ੍ਹਾਂ ਨੇ ਲੜਾਈ ਲੜੀ, ਬਲੀਦਾਨ ਦਿੱਤਾ ਅਤੇ ਸਾਡੀ ਆਜ਼ਾਦੀ ਨੂੰ ਯਕੀਨੀ ਬਣਾਇਆ। ਅਸੀਂ ਸ਼ੁਕਰਗੁਜ਼ਾਰ ਹਾਂ",
      //       },
      //       {
      //         id: 4,
      //         message:
      //           "ਇੱਕ ਵਿਅਕਤੀ ਹੁਣ ਉੱਥੇ ਨਹੀਂ ਹੋ ਸਕਦਾ ਪਰ ਉਨ੍ਹਾਂ ਦੇ ਵਿਚਾਰ ਜਿਉਂਦੇ ਰਹਿੰਦੇ ਹਨ। ਸੁਤੰਤਰਤਾ ਦੇ ਲੰਬੇ ਸਮੇਂ ਤੱਕ ਜੀਓ!",
      //       },
      //       {
      //         id: 5,
      //         message:
      //           "ਅਸੀਂ ਆਪਣੇ ਸੁਤੰਤਰਤਾ ਸੈਨਾਨੀਆਂ ਨੂੰ ਸਲਾਮ ਕਰਦੇ ਹਾਂ ਜਿਨ੍ਹਾਂ ਨੇ ਸਾਨੂੰ ਰਾਸ਼ਟਰ ਪਹਿਲਾਂ ਅਤੇ ਹਮੇਸ਼ਾ ਪਹਿਲਾਂ ਦੀ ਸਿੱਖਿਆ ਦਿੱਤੀ।",
      //       },
      //     ],

      //     thankyou: "ਧੰਨਵਾਦ",
      //     thankyou_message:
      //       " ਇਸ ਮੁਹਿੰਮ ਦਾ ਹਿੱਸਾ ਬਣਨ ਲਈ। ਤੁਸੀਂ  ਆਪਣੀ ਸ਼ਰਧਾਂਜਲੀ ਦੀ ਵੀਡੀਓ ਰਿਕਾਰਡਿੰਗ ਈਮੇਲ ਰਾਹੀਂ ਪ੍ਰਾਪਤ ਕਰੋਗੇ ।",
      //   },
      // },
      guj: {
        translations: {
          lang: "Gujarati",
          home: "હોમ",
          click_to_pay_tribute: "શ્રદ્ધાંજલિ આપવા માટે ક્લિક કરો",
          click_to_change_lang: "ભાષા બદલવા માટે ક્લિક કરો ",
          pay_tribute: "શ્રદ્ધાંજલિ આપવી",
          change_language: "ભાષા બદલો",
          home_btn: "./home_act.svg",
          tribute_btn: "./tribute.svg",
          lang_btn: "./language.svg",
          digital_jyot: "ડિજિટલ જ્યોત",
          tribute_garden: "ડિજિટલ જ્યોતઃ શહીદોને શ્રદ્ધાંજલિ",
          digital_intro:
            "શ્રદ્ધાંજલિ આપવા કનોટ પ્લેસ, નવી દિલ્હીના સેન્ટ્રલ પાર્કમાં ડિજિટલ જ્યોત પ્રગટાવો.",
          instructions: "નીચેના પગલાંઓ અનુસરો",
          step1: "પગલું 1 : ",
          step2: "પગલું 2 : ",
          step3: "પગલું 3 : ",
          step4: "પગલું 4 : ",
          step1_inst: "આપનો ફોટો પાડો",
          step2_inst: " આપની વિગતો દાખલ કરો",
          step3_inst: "આપનો સંદેશ પસંદ કરો",
          step4_inst: "આપના તરફથી શ્રદ્ધાંજલિ આપો",
          note_head: "નોંધ : ",
          note: "આપની શ્રદ્ધાંજલિ સેન્ટ્રલ પાર્ક (કનોટ પ્લેસ) ખાતે એલઇડી સ્ક્રીનમાં દર્શાવાશે અને આકાશમાં ડિજિટલ જ્યોત પ્રગટશે.આપે અર્પેલી શ્રદ્ધાંજલિનું વિડિયો રેકોર્ડિંગ ઈમેલ/એસએમએસ દ્વારા શેર કરવામાં આવશે",

          live: "જીવંત",
          about: "વિશે",
          tribute_paid_so_far: "અત્યાર સુધી આપવામાં આવેલી શ્રદ્ધાંજલિ",
          submit: "સબમિટ કરો",
          name: "આપનું નામ",
          phone: "ફોન નંબર",
          email: "ઈ-મેઈલ આઈડી",
          message: "સંદેશ",
          upload_image: "ફોટો અપલોડ કરો",
          tribute_number: "આપે અર્પેલી શ્રદ્ધાંજલિનો નંબર :",
          current_tribute_number: "વર્તમાન શ્રદ્ધાંજલિ નંબર : ",
          quote: [
            {
              id: 1,
              message:
                "તેઓએ આપણી આવતીકાલ માટે તેમના આજનો ત્યાગ કર્યો. એક કૃતજ્ઞ રાષ્ટ્ર કહે છે આભાર!",
            },
            {
              id: 2,
              message:
                "આપણે તે બધાને યાદ કરીએ છીએ જેમણે આપણી સ્વતંત્રતાને શક્ય બનાવી. આપણે ક્યારેય નહીં ભૂલીએ!",
            },
            {
              id: 3,
              message:
                "એક રાષ્ટ્ર એ તમામ લોકોને યાદ કરે છે, જેઓ આઝાદી માટે લડ્યા હતા, જેમણે બલિદાન આપ્યું હતું અને આપણી સ્વતંત્રતાની ખાતરી આપી હતી. આપણે આભારી છીએ",
            },
            {
              id: 4,
              message:
                "કોઈ વ્યક્તિ હવે ન પણ હોય, પરંતુ તેમના વિચારો જીવંત રહે છે. સ્વતંત્રતા ચિરાયુ રહે!",
            },
            {
              id: 5,
              message:
                "આપણે આપણા સ્વાતંત્ર્ય સેનાનીઓને સલામ કરીએ છીએ, જેમણે આપણને રાષ્ટ્ર પ્રથમ અને હંમેશા પ્રથમ શીખવ્યું છે.",
            },
          ],

          thankyou: "આપનો આભાર ",
          thankyou_message:
            " આ અભિયાનનો ભાગ બનવા બદલ. આપને ઈમેલ દ્વારા આપે અર્પેલી શ્રદ્ધાંજલિનું વિડિયો રેકોર્ડિંગ મળશે.",
        },
      },
      tam: {
        translations: {
          lang: "Tamil",
          home: "வீடு",
          click_to_pay_tribute: "அஞ்சலி செலுத்த கிளிக் செய்யவும்",
          click_to_change_lang: "மொழியை மாற்ற கிளிக் செய்யவும்",
          pay_tribute: "அஞ்சலி செலுத்துங்கள்",
          change_language: "மொழியை மாற்றவும்",
          home_btn: "./home_act.svg",
          tribute_btn: "./tribute.svg",
          lang_btn: "./language.svg",
          digital_jyot: "ிஜிட்டல் ஜஜோதி",
          tribute_garden: "ிஜிட்டல் ஜஜோதி : தி஬ோகிகளுக்கு அஞ்சயி",
          digital_intro:
            "ுது தில்யி஬ின், கன்னோட் ப்ஜரஸில் சசன்ட்஭ல் போர்க்கில் டிஜிடல் ஜஜோதிய஬ ஏற்மி தி஬ோகிகளுக்கு அஞ்சயி சசலுத்துங்கள்..",
          instructions: "பின்லரும் படிகயர பின்பற்றுங்கள",
          step1: "படி 1 : ",
          step2: "படி 2 : ",
          step3: "படி 3 : ",
          step4: "படி 4 : ",
          step1_inst: " உங்கள் புயகபடத்யத க்ரிக் சசய்யுங்கள்.",
          step2_inst: "உங்கள் லில஭ங்கயர உள்ரிடுங்கள்.",
          step3_inst: "உங்கள் சசய்திய஬ ஜதர்வு சசய்யுங்கள்.",
          step4_inst: "அஞ்சயி சசலுத்துங்கள்.",
          note_head: "ுமிப்ப : ",
          note: "உங்கள் அஞ்சயி சசன்ட்஭ல் போர்க்( கன்னோட் ப்ஜரஸ்) ல் உள்ர எல்இடி ஸ்க்ரீனில் கோட்டப்பட்டு , லோனத்தில் டிஜிட்டல் ஜஜோதிய஬ ஏற்றும். ஫ின்னஞ்சல்/எஸ் எம் எஸ் மூய஫ோக உங்கள் அஞ்சயி஬ின் லடிீ ஜ஬ோ பதிவு அனுப்பப்படும",

          live: "வாழ்க",
          about: "பற்றி",
          tribute_paid_so_far: "இதுவரை அஞ்சலி செலுத்தப்பட்டது",
          submit: "சமர்ப்பிக்க",
          name: "உங்கள் சப஬ர",
          phone: "தொலைபேசி எண்",
          email: "மின்னஞ்சல் ஐடி",
          message: "சசய்தி",
          upload_image: "படத்யத பதிஜலற்மவும",
          tribute_number: "உங்கள் அஞ்சயி஬ின் எண: ",
          current_tribute_number: "தற்ஜபோயத஬ அஞ்சயி எண் : ",
          quote: [
            {
              id: 1,
              message:
                "அவர்கள் இன்றைய நாளை தியாகம் செய்கிறார்கள் நம் நாளைக்காக. நன்றியுள்ள தேசம் நன்றி கூறுகிறது",
            },
            {
              id: 2,
              message:
                "நமது சுதந்திரத்தை சாத்தியமாக்கிய அனைவரையும் நாம் நினைவில் வைத்திருக்கிறோம். நாம் ஒருபோதும் மறக்க மாட்டோம்!",
            },
            {
              id: 3,
              message:
                "நமது சுதந்திரத்தை ப் போராடிய, தியாகம் செய்த மற்றும் உறுதி செய்த அனைவரையும் ஒரு தேசம் நினைவுகூர்கிறது. நாங்கள் நன்றியுள்ளவர்களாக இருக்கிறோம்",
            },
            {
              id: 4,
              message:
                "ஒரு நபர் இனி அங்கு இல்லாமல் இருக்கலாம், ஆனால் அவர்களின் கருத்துக்கள் வாழ்கின்றன. சுதந்திர வாழ்க!",
            },
            {
              id: 5,
              message:
                "முதலில் முதலில் நமக்கு தேசத்தைக் கற்பித்த நமது சுதந்திரப் போராட்ட வீரர்களுக்கு நாங்கள் தலைவணங்குகிறோம்.",
            },
          ],

          thankyou: "நன்றி",
          thankyou_message:
            "இந்த இ஬க்கத்தில் இயைந்ததற்கு நன்மி. ஫ின்னஞ்சல் லறி஬ோக உங்கள் அஞ்சயி஬ின் லடிீ ஜ஬ோ பதிவு உங்களுக்கு லந்துஜசரும்.",
        },
      },
      tel: {
        translations: {
          lang: "Telugu",
          home: "పరిచయ పేజీ ",
          click_to_pay_tribute: "నివాళి అర్పించేందుకు క్లిక్ చేయండి",
          click_to_change_lang: "భాష మార్చడానికి క్లిక్ చేయండి  ",
          pay_tribute: "నివాళులర్పిస్తారు",
          change_language: "భాష మార్చు",
          home_btn: "./home_act.svg",
          tribute_btn: "./tribute.svg",
          lang_btn: "./language.svg",
          digital_jyot: "Digital Jyot",
          tribute_garden: "అమరవీరులకు నివాళి",
          digital_intro:
            "మీ నివాళులు అర్పించడం కోసం, న్యూ ఢిల్లీలోని కన్నాట్ ప్లేస్‌లో ఉన్న సెంట్రల్ పార్క్ వద్ద డిజిటల్ జ్యోతిని వెలిగించండి .",
          instructions: "దిగువ దశలను అనుసరించండి",
          step1: "దశ 1 : ",
          step2: "దశ 2 : ",
          step3: "దశ 3 : ",
          step4: "దశ 4 : ",
          step1_inst: "మీ ఫోటోను క్లిక్ చెయ్యండి",
          step2_inst: "మీ వివరాలను నమోదు చెయ్యండి",
          step3_inst: "మీ సందేశాన్ని ఎంచుకోండి",
          step4_inst: "మీ నివాళి అర్పించండి",
          note_head: "గమనిక : ",
          note: "మీ నివాళి సెంట్రల్ పార్క్ (కనాట్ ప్లేస్) లో  ఎల్‌ఈ‌డి స్క్రీన్ మీద ఫ్లాష్ అవుతుంది మరియు ఆకాశంలో డిజిటల్ జ్యోతిని వెలిగిస్తుంది. మీ నివాళి వీడియో రికార్డింగ్ ఇ-మెయిల్/ఎస్‌ఎం‌ఎస్ ద్వారా షేర్ చేయబడుతుంది.",

          live: "లైవ్ షో",
          about: "గురించి",
          tribute_paid_so_far: "ఇప్పటివరకు నివాళులు అర్పించారు",
          submit: "సమర్పించండి",
          name: "మీ పేరు",
          phone: "ఫోన్ నెంబరు",
          email: "ఇ-మెయిల్ ఐడి",
          message: "సందేశం",
          upload_image: "ఇమేజ్ అప్లోడ్ చెయ్యండి",
          tribute_number: "మీ నివాళి నంబర్ :  ",
          current_tribute_number: "ప్రస్తుత ట్రిబ్యూట్ నంబర్ : ",
          quote: [
            {
              id: 1,
              message:
                "వారు మా భవిష్యత్తు కోసం తమ వర్తమానం త్యాగం చేసారు. కృతజ్ఞత గల దేశం ధన్యవాదాలు చెబుతుంది!",
            },
            {
              id: 2,
              message:
                "మన స్వేచ్ఛను సాధ్యం చేసిన వారందరినీ మనం గుర్తుంచుకుంటాం. మేము ఎప్పటికీ మరచిపోలేము!",
            },
            {
              id: 3,
              message:
                "మన స్వాతంత్ర్యం కోసం పోరాడి, త్యాగం చేసి మరియు సాధ్యమయ్యేటట్లు చేసిన  అందరినీ ఒక జాతి గుర్తుంచుకుంటుంది. మనం కృతజ్ఞులం.",
            },
            {
              id: 4,
              message:
                "ఒక వ్యక్తి మద్దతు చేయడానికి ఇక పై అక్కడ ఉండకపోవచ్చు కానీ వారి ఆలోచనలు మాత్రం సజీవంగా ఉంటాయి. మన స్వాతంత్ర్యం కలకాలం నిలువు గాక.",
            },
            {
              id: 5,
              message:
                "జాతి ప్రధమం మరియు ఎల్లప్పుడూ ప్రథమం అని మనకు బోధించిన మన స్వాతంత్ర్య సమరయోధులకు మనం వందనం చేస్తున్నాం. ",
            },
          ],

          thankyou: "ధన్యవాదాలు  ",
          thankyou_message:
            " ధన్యవాదాలు. మీరు ఇ-మెయిల్ ద్వారా మీ నివాళి వీడియో రికార్డింగ్‌ను పొందుతారు",
        },
      },
    },
    fallbackLng: "en",
    debug: true,

    // have a common namespace used around the full app
    ns: ["translations"],
    defaultNS: "translations",

    keySeparator: false, // we use content as keys

    interpolation: {
      escapeValue: false,
    },
  });

export default i18n;
