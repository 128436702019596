import React from "react";
// import PropTypes from "prop-types";
import loop_rings from "../Assets/temp.jpg";
import { Container, Row, Col, NavDropdown } from "react-bootstrap";
import ministryLogo from "../Assets/ministrylogo.png";
import azadiLogo from "../Assets/azadi.svg";
// import live from "./live_act_eng.svg";
import lang from "../Assets/languagee.svg";
import "./Main.css";
import { useTranslation, Trans } from "react-i18next";
import { useNavigate } from "react-router-dom";

function YoutubeEmbed() {
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
  };
  return (
    <div
      className="img-fluid main-cont"
      style={{
        backgroundPosition: "center",
        backgroundImage: `url(${loop_rings})`,
        backgroundSize: "cover",
        textAlign: "center",
        height: "100vh",
        backgroundRepeat: "no-repeat",
        backgroundColor: "#040302",
        // fontFamily: "sans-serif",
        overflow: "scroll",
        color: "#C1A56E",
      }}
    >
      <Container fluid="xxxl">
        <Row>
          <Col xs lg="6">
            <a className="ministryLogo px-2" href="/">
              <img src={ministryLogo} className="img-fluid"></img>
            </a>
          </Col>

          <Col xs lg="6">
            <a className="subLogo px-2" href="/">
              <img src={azadiLogo} className="img-fluid"></img>
            </a>
          </Col>

          <Col xs lg="1" className="lang_div">
            <NavDropdown
              title={
                <div className="langLogo float">
                  <img
                    className="my-float img-fluid lang"
                    src={lang}
                    alt="Language"
                  />
                </div>
              }
              id="basic-nav-dropdown"
            >
              <NavDropdown.Item onClick={() => changeLanguage("en")}>
                English
              </NavDropdown.Item>
              <div class="dropdown-divider"></div>
              <NavDropdown.Item onClick={() => changeLanguage("hin")}>
                हिन्दी
              </NavDropdown.Item>
              {/* <div className="dropdown">
                <div class="dropdown-divider"></div>
                <NavDropdown.Item onClick={() => changeLanguage("as")}>
                  Assames
                </NavDropdown.Item>
                <div class="dropdown-divider"></div>
                <NavDropdown.Item onClick={() => changeLanguage("ban")}>
                  Bangla
                </NavDropdown.Item>
                <div class="dropdown-divider"></div>
                <NavDropdown.Item onClick={() => changeLanguage("kan")}>
                  Kannada
                </NavDropdown.Item>
                <div class="dropdown-divider"></div>
                <NavDropdown.Item onClick={() => changeLanguage("mal")}>
                  Malayalam
                </NavDropdown.Item>
                <div class="dropdown-divider"></div>
                <NavDropdown.Item onClick={() => changeLanguage("mar")}>
                  Marathi
                </NavDropdown.Item>
                <div class="dropdown-divider"></div>
                <NavDropdown.Item onClick={() => changeLanguage("od")}>
                  Odiya
                </NavDropdown.Item>
                <div class="dropdown-divider"></div>
                <NavDropdown.Item onClick={() => changeLanguage("pun")}>
                  Punjabi
                </NavDropdown.Item>
                <div class="dropdown-divider"></div>
                <NavDropdown.Item onClick={() => changeLanguage("od")}>
                  Odiya
                </NavDropdown.Item>
                <div class="dropdown-divider"></div>
                <NavDropdown.Item onClick={() => changeLanguage("guj")}>
                  Gujrati
                </NavDropdown.Item>
                <div class="dropdown-divider"></div>
                <NavDropdown.Item onClick={() => changeLanguage("tam")}>
                  Tamil
                </NavDropdown.Item>
                <div class="dropdown-divider"></div>
                <NavDropdown.Item onClick={() => changeLanguage("tel")}>
                  Telugu
                </NavDropdown.Item>
              </div> */}
            </NavDropdown>
          </Col>
        </Row>
        <Row>
          <Col style={{ textAlign: " -webkit-right" }}>
            <div
              className="img-fluid live d-flex justify-content-center"
              onClick={() => {
                navigate("/");
              }}
              style={{
                backgroundImage: `url(./inactive.svg)`,
                backgroundRepeat: "no-repeat",
                position: "relative",
                height: "35px",
                maxWidth: "70px",
                padding: "7px",
              }}
            >
              <p className="btn-text">{t("home")}</p>
            </div>
          </Col>
          <Col style={{ textAlign: "start" }}>
            <div
              className="img-fluid live d-flex justify-content-center"
              onClick={() => {
                navigate("/live");
              }}
              style={{
                backgroundImage: `url(./live_act.svg)`,
                backgroundRepeat: "no-repeat",
                position: "relative",
                height: "35px",
                maxWidth: "70px",
                padding: "7px",
              }}
            >
              <p className="btn-text">{t("live")}</p>
            </div>
          </Col>
        </Row>
      </Container>

      <div className="mt-5" style={{ paddingTop: "60px" }}>
        <p className="digital_intro font-eng text-center">
          {t("tribute_garden")}
        </p>
        <div className="video-responsive  pt-5">
          <iframe
            src="https://www.youtube.com/embed/eV36ZiFiutY"
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
            title="Embedded youtube"
            className="py-2"
            style={{ borderRadius: "20px" }}
          />
        </div>
      </div>
    </div>
  );
}

// YoutubeEmbed.propTypes = {
//   embedId: PropTypes.string.isRequired,
// };

export default YoutubeEmbed;
