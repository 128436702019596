import { Container, Row, Col } from "react-bootstrap";
import { useTranslation, Trans } from "react-i18next";
import bg from "../Assets/bg.jpg";
import Header from "./Header";
import "./Main.css";
import { useNavigate } from "react-router-dom";

function About() {
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  return (
    <>
      <div
        className="img-fluid"
        style={{
          backgroundImage: `url(${bg})`,
          backgroundSize: "cover",
          textAlign: "center",
          backgroundRepeat: "no-repeat",
          overflowY: "scroll",
          overflowX: "hidden",
          height: "100vh",
          color: "#C1A56E",
        }}
      >
        <Header />
        <Row>
          <Col style={{ textAlign: " -webkit-right" }}>
            <div
              className="img-fluid live d-flex justify-content-center"
              onClick={() => {
                navigate("/");
              }}
              style={{
                backgroundImage: `url(./inactive.svg)`,
                backgroundRepeat: "no-repeat",
                position: "relative",
                height: "35px",
                maxWidth: "70px",
                padding: "7px",
              }}
            >
              <p className="btn-text  cream">{t("home")}</p>
            </div>
          </Col>
          <Col style={{ textAlign: "start" }}>
            <div
              className="img-fluid live d-flex justify-content-center"
              onClick={() => {
                navigate("/about");
              }}
              style={{
                backgroundImage: `url(./active.svg)`,
                backgroundRepeat: "no-repeat",
                position: "relative",
                height: "35px",
                maxWidth: "70px",
                padding: "7px",
              }}
            >
              <p className="btn-text black">{t("about")}</p>
            </div>
          </Col>
        </Row>
        <Container className="message-cont pt-4">
          <Row>
            <Col className="message-form">
              <p>{t("about_desc1")}</p>
              <p>{t("about_desc2")}</p>
              <p>{t("about_desc3")}</p>
              <p>{t("about_desc4")}</p>
              <p>{t("about_desc5")}</p>
              <p>{t("about_desc6")}</p>
              <p>{t("about_desc7")}</p>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
}

export default About;
