import logo from "./logo.svg";
import "./App.css";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Main from "./Components/Main";
import About from "./Components/About";
import YoutubeEmbed from "./Components/YoutubeEmbed";
import Video from "./Components/Video";

function App() {
  return (
    <>
      <BrowserRouter>
        <Routes>
          <Route path="/" exact element={<Main />} />
          <Route path="/about" exact element={<About />} />
          <Route path="/live" exact element={<YoutubeEmbed />} />
          <Route path="/video/:videoId" exact element={<Video />} />
        </Routes>
      </BrowserRouter>
    </>
  );
}

export default App;
