import loop_rings from "../Assets/temp.jpg";
import ministryLogo from "../Assets/ministrylogo.png";
import azadiLogo from "../Assets/azadi.svg";
import download from "../Assets/download.svg";
import whatsapp from "../Assets/whatsapp.svg";
import facebook from "../Assets/facebook.svg";
import { Container, Row, Col } from "react-bootstrap";
import { useTranslation, Trans } from "react-i18next";
import "./Main.css";
import { useLocation } from "react-router-dom";

function Video() {
  const { t, i18n } = useTranslation();

  // const videoURL =
  //   "https://storage.googleapis.com/digital-jyot.appspot.com/GmdH9KUNRU01qc9AdgNw.mp4";
  // const video = videoURL.split("/");
  // const videoID = video[video.length - 1].split(".")[0];

  const location = useLocation();
  const videoID = location.pathname.split("/")[2];
  const videoURL = `https://storage.googleapis.com/digital-jyot.appspot.com/${videoID}.mp4`;

  return (
    <>
      <div
        className="img-fluid main-cont"
        style={{
          backgroundPosition: "center",
          backgroundImage: `url(${loop_rings})`,
          backgroundSize: "cover",
          textAlign: "center",
          backgroundRepeat: "no-repeat",
          backgroundColor: "#040302",
          fontFamily: "sans-serif",
          //   overflowY: "scroll",
          overflowX: "hidden",
          color: "#C1A56E",
          height: "100vh",
        }}
      >
        <Container fluid>
          <Row>
            <Col xs lg="6">
              <a className="ministryLogo px-2" href="/">
                <img src={ministryLogo} className="img-fluid"></img>
              </a>
            </Col>

            <Col xs lg="6">
              <a className="subLogo">
                <img src={azadiLogo} className="img-fluid"></img>
              </a>
            </Col>
          </Row>
        </Container>
        <Container fluid className="visible">
          <Row>
            {" "}
            <Col>
              {/* <img
                className="img-fluid download"
                src={download}
              /> */}
              <h6>Share on : </h6>
              {"         "}
              <a
                href={
                  "https://www.facebook.com/sharer/sharer.php?u=https://digitaltribute.in/video/" +
                  videoID
                }
                target="_blank"
              >
                <img className="img-fluid facebook" src={facebook} />
              </a>
              {"         "}
              <a
                href={
                  "whatsapp://send?text=https://digitaltribute.in/video/" +
                  videoID
                }
                data-action="share/whatsapp/share"
                target="_blank"
              >
                <img className="img-fluid whatsapp" src={whatsapp} />
              </a>
            </Col>
          </Row>
        </Container>

        <div className="my-3 vertical-center" style={{ paddingTop: "60px" }}>
          <div className="video">
            <iframe
              src={videoURL}
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
              title="Generated Video"
              className="py-2"
              style={{
                borderRadius: "20px",
              }}
            />
          </div>
        </div>
        <Container fluid className="hide mt-5 pt-5">
          <Row>
            <h6>Share on : </h6>
            <Col style={{ textAlign: " -webkit-right" }}>
              <a
                href={
                  "https://www.facebook.com/sharer/sharer.php?u=https://digitaltribute.in/video/" +
                  videoID
                }
                target="_blank"
              >
                <img className="img-fluid facebook" src={facebook} />
              </a>
            </Col>
            <Col style={{ textAlign: "start" }}>
              <a
                href={
                  "whatsapp://send?text=https://digitaltribute.in/video/" +
                  videoID
                }
                data-action="share/whatsapp/share"
                target="_blank"
              >
                <img className="img-fluid whatsapp" src={whatsapp} />
              </a>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
}

export default Video;
